@import 'style/helpers';

.partners {
	@include padding-sm;
	&__headline {
		line-height: 0.6;
		margin-bottom: 1.38rem;
		@include css-lock(80, 100, $desktop, $desktopHD);
		@include lg {
			@include css-lock(60, 80, $desktop, $desktopHD); }
		@include sm {
			@include css-lock(52, 60, $desktop, $desktopHD); } }
	&__items {
		display: flex;
		flex-wrap: wrap;
		margin: -15px; }
	&__item-wrapper {
		width: 33.33%;
		padding: 15px;
		@include r(767) {
			width: 100%; } }
	&__item {
		background-color: #131315;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 90px 40px;
		img {
			max-width: 100%;
			max-height: 100%;
			height: auto; } } }


