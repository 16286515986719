@mixin css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px) {
  #{$property}: #{$max-size}#{$unit};
  #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));
  @media (max-width: #{$min-width}#{$unit}) {
    #{$property}: #{$min-size}#{$unit}; }
  @media (min-width: #{$max-width}#{$unit}) {
    #{$property}: #{$max-size}#{$unit}; } }

@mixin r($width) {
  @media only screen and (max-width: $width + "px") {
    @content; } }

@mixin r-land($width) {
  @media only screen and (max-width: $width + "px") and (orientation: landscape) {
    @content; } }

@mixin r-port($width) {
  @media only screen and (max-width: $width + "px") and (orientation: portrait) {
    @content; } }

@mixin rmin($width) {
  @media only screen and (min-width: $width + "px") {
    @content; } }

@mixin semi-bold {
  font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }

@mixin extra-bold {
  font-family: 'Montserrat-ExtraBold', Helvetica, sans-serif; }

@mixin bold {
  font-family: 'Montserrat-Bold', Helvetica, sans-serif; }

@mixin light {
  font-family: 'Montserrat-Light', Helvetica, sans-serif; }

@mixin medium {
  font-family: 'Montserrat-Medium', Helvetica, sans-serif; }

@mixin hairline {
  font-family: 'Montserrat-Hairline', Helvetica, sans-serif; }

@mixin regular {
  font-family: 'Montserrat-Regular', Helvetica, sans-serif; }

@mixin lg {
  @include r($desktop) {
    @content; } }

@mixin md {
  @include r($tablet) {
    @content; } }

@mixin md-land {
  @include r-land($tablet) {
    @content; } }

@mixin md-port {
  @include r-port($tablet) {
    @content; } }

@mixin sm {
  @include r($mob) {
    @content; } }

@mixin padding {
  padding: 2rem 1.5rem;

  @include md {
    padding: 100px 46px; } }

@mixin padding-sm {
  padding: 1rem 1.5rem;
  @include r(1600) {
    padding: 1rem 1rem; }
  @include lg {
    padding: 1rem 0.7rem; }
  @include md {
    padding: 50px 46px; }
  @include sm {
    padding: 30px 16px; } }
