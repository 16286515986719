.burger {
  $this: &;
  background-color: transparent;
  border-radius: 2px;
  border: none;
  color: #fff;
  padding: 0;
  width: 24px;

  &__in {
    background-color: currentColor;
    border-radius: inherit;
    height: 4px; }

  &:before,
  &:after {
    background-color: currentColor;
    border-radius: inherit;
    content: '';
    display: block;
    height: 4px;
    position: relative; }

  &__in,
  &:before {
    margin-bottom: 4px; }

  &.is-active {
    #{$this} {
      &__in {
        opacity: 0; } }

    &:before {
      transform: rotate(45deg);
      top: 8px; }

    &:after {
      transform: rotate(-45deg);
      top: -8px; } } }
