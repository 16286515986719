@import 'style/helpers.sass';

.footer {
  padding: 0.48rem 1.5rem;
  position: relative;
  @include css-lock(14, 16, $desktop, $desktopHD);

  @include lg {
    padding: 0.7rem 1.5rem;
    @include css-lock(12, 14, $tablet, $desktop); }

  @include md {
    padding: 15px; }

  &__in {
    align-items: center;
    display: flex;
    justify-content: space-between; }

  &:before {
    background-image: linear-gradient(to bottom, rgba(92, 84, 101, 0.5), rgba(92, 84, 101, 0.5)), linear-gradient(to right, rgba(0, 0, 0, 0.27) 0%, #ffffff 54%, rgba(0, 0, 0, 0));
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    top: 0;
    width: 100%; }

  &__logo {
    width: 170px;

    @include lg {
      width: 100px; } }

  &__link {
    color: #5b5662;
    font-size: 18px;
    text-decoration: none;
    transition: color ease-out 0.3s;
    @include css-lock(14, 18, $desktop, $desktopHD);
    @include regular;

    @include lg {
      @include css-lock(12, 14, $tablet, $desktop); }

    &:not(:last-of-type) {
      margin-right: 0.5rem;

      @include md {
        margin-right: 0;
        margin-bottom: 0.1rem; } }

    &:hover {
      color: #ffffff; } }

  &__text {
    color: #5b5662;
    display: none;
    font-size: 11px;
    line-height: 1.55;
    text-align: center;
    @include medium;

    @include md {
      display: block;
      margin: auto;
      max-width: 280px; } }

  &__logo,
  &__nav {
    @include md {
      display: none; } }

  &__links {
    @include md {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      text-align: center;
      justify-content: center;
      width: 100%; } } }
