@import 'style/helpers';

.btn {
  background-size: 120%;
  background-position: 0%;
  border: none;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  line-height: 1;
  min-width: 3.8rem;
  overflow: hidden;
  padding: 0.25rem 0.15rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  @include css-lock(24, 30, $desktop, $desktopHD);
  @include bold;

  @include lg {
    @include css-lock(18, 24, $tablet, $desktop); }

  &__text {
    position: relative; }

  &:before {
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity ease-out 0.3s;
    width: 100%; }

  &:hover {
    background-position: 100% 0%;

    &:before {
      opacity: 1; } }

  &_pink {
    background-image: linear-gradient(to right, #ff2dd6 15%, #ff120c 60%);

    &:before {
      background-image: linear-gradient(to right, #ff120e, #ff992d); } }

  &_blue {
    background-image: linear-gradient(to right, #9039f6, #00f1f9);

    &:before {
      background-image: linear-gradient(to right, #01eff9, #00e215); } }

  &_green {
    background-image: linear-gradient(to right, #ffeb4f, #00e300);

    &:before {
      background-image: linear-gradient(to right, #00e300, #01e0e3); } }

  &_violet {
    background-image: linear-gradient(to right, #f419a8, #7f6fdb 50%, #00f1f9); }

  &_full {
    width: 100%; } }
