@import 'style/helpers';

@mixin gradient {
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.45)), linear-gradient(64deg, #0a050d -7%, #120e17 104%);
  opacity: 0.05; }

.slider {
  $this: &;
  position: relative;

  &__item {
    display: block;
    color: #fff;
    height: 100%;
    padding: 1rem;
    position: relative;
    margin: auto;
    max-width: calc(100% - 0.3rem);
    text-decoration: none;
    user-select: none;

    &:before {
      pointer-events: none;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @include gradient; }

    img {
      display: block;
      margin: auto;
      max-height: 1.5rem;
      max-width: 3.8rem;
      width: 100%; } }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%; }

  &__image {
    margin-top: -30%;
    padding-top: 48%;
    margin-bottom: 40px;
    width: 100%;
    position: relative;
    @include md {
      margin-top: -100px; }
    @include sm {
      margin-top: -70px; }
    img {
      max-height: none;
      max-width: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover; } }

  &__text {
    flex-grow: 1;
    line-height: 1.67;
    letter-spacing: 0.95px;
    margin-bottom: 0.8rem;
    text-align: center;
    @include css-lock(21, 30, $desktop, $desktopHD);
    @include regular;

    @include lg {
      @include css-lock(16, 21, $tablet, $desktop); } }

  &__more {
    display: flex;
    align-items: center;
    @include regular;
    color: #21c4ff;
    font-size: 20px;
    line-height: 2.37;
    letter-spacing: 0.05em;
    svg {
      display: block;
      margin-left: 4px;
      height: 30px;
      width: 14px;
      fill: #21c4ff; }
    @include sm {
      font-size: 16px; } }

  &__btn {
    cursor: pointer;
    height: 100%;
    position: absolute;
    top: 0;
    width: 2rem;

    &_prev {
      left: 0.15rem;
      transform: translateX(-100%);

      @include md {
        left: 35px; } }

    &_next {
      right: 0.45rem;
      transform: translateX(100%);

      @include md {
        right: 45px; } } }

  .slick-track {
    align-items: stretch;
    display: flex; }

  .slick-list {
    overflow: visible; }

  .slick-slide {
    align-items: stretch;
    display: flex;
    height: auto;

    div {
      width: 100%; } }

  &_news {
    margin: 0 -55px;
    #{$this}__text {
      text-align: left;
      margin-bottom: 10px; }
    #{$this}__item {
      padding: 0.9rem; } } }


