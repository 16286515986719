@import 'style/helpers';

.lang {
  $this: &;
  color: #fff;
  position: relative;

  &__btn {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    z-index: 1; }

  &__icon {
    padding-left: 11.5px;

    svg {
      display: block;
      fill: currentColor;
      transition: transform ease-in-out 0.3s;
      transform: rotate(0deg);
      width: 11px; } }

  &__links {
    position: relative;
    @include md {
      height: 100%;
      left: 0;
      overflow: auto;
      position: fixed;
      top: 0;
      text-align: center;
      width: 100%; } }

  &__close {
    display: none;
    z-index: 11;
    position: absolute;
    top: 35px;
    right: 16px;
    width: 24px;
    height: 20px;
    background: transparent;
    border: none;
    border-radius: 0;
    @include md {
      display: block; }
    &:before, &:after {
      display: block;
      content: '';
      position: absolute;
      top: 40%;
      left: 0;
      border-radius: 2px;
      background-color: #0a050d;
      height: 4px;
      width: 100%; }
    &:before {
      transform: rotate(45deg); }
    &:after {
      transform: rotate(-45deg); } }


  &__list {
    background-color: #f8f8fd;
    line-height: 1;
    left: -30px;
    min-width: 160px;
    padding: 35px 30px;
    padding-top: 110px;
    position: absolute;
    top: -58px;
    white-space: nowrap;

    @include md {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      min-height: calc(var(--vh, 1) * 100);
      left: 0;
      padding: 80px 30px;
      position: fixed;
      top: 0;
      justify-content: center;
      width: 100%;
      z-index: 10; }

    &:before {
      background-image: linear-gradient(to bottom, rgba(194, 194, 203, 0), #413f44);
      bottom: 0;
      content: '';
      height: 20%;
      opacity: 0.32;
      left: 0;
      position: absolute;
      width: 100%;

      @include md {
        height: 50%; } }

    &:after {
      background-image: linear-gradient(to right, #ffeb4f, #00e300);
      bottom: 0;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%; } }

  &__item {
    position: relative;
    z-index: 1;

    &:not(:last-of-type) {
      margin-bottom: 22px; }

    &:hover {
      #{$this}__link {
        color: #92929c; } } }

  &__link {
    color: inherit;
    text-decoration: none;
    transition: color ease-out 0.3s;

    &.is-active {
      color: #92929c;
      cursor: default;
      pointer-events: none; } }

  &.is-opened {
    color: #000;
    z-index: 8;

    @include md {
      #{$this}__btn {
        display: none; } }

    #{$this}__icon {
      svg {
        transform: rotate(180deg); } } } }
