@import 'style/helpers';

.item {
  $this: &;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  opacity: 0;
  padding-right: 12vmin;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  justify-content: center;

  &:nth-of-type(6n + 1) {
    transform: translateY(-1.1vmin);

    #{$this}__title {
      background-image: linear-gradient(to right, #00f1f9, #00f93a 52%); } }

  &:nth-of-type(6n + 2) {
    transform: translateY(7vmin);

    #{$this}__title {
      background-image: linear-gradient(to right, #ffeb4f, #00e300 93%); } }

  &:nth-of-type(6n + 3) {
    transform: translateY(3vmin);

    #{$this}__title {
      background-image: linear-gradient(to right, #7f6fdb, #00f1f9 94%); } }

  &:nth-of-type(6n + 4) {
    transform: translateY(7vmin);

    #{$this}__title {
      background-image: linear-gradient(79deg, #f12d2d -8%, #e1f701 91%); } }

  &:nth-of-type(6n + 5) {
    transform: translateY(-3vmin);

    #{$this}__title {
      background-image: linear-gradient(84deg, #f419a8 -4%, #7f6fdb 52%); } }

  &:nth-of-type(6n) {
    #{$this}__title {
      background-image: linear-gradient(82deg, #ff2dd6 -19%, #ff120c 80%); } }

  @include md {
    padding-top: 60px;
    padding-right: 0;
    padding-bottom: 40px;
    text-align: center;
    transform: translateY(0);
    justify-content: flex-start;

    &:nth-of-type(n),
    &:nth-of-type(2n) {
      transform: translateY(0); } }

  &__title {
    font-size: 4vmin;
    line-height: 1.48;
    letter-spacing: 1.52px;
    margin-bottom: 45px;
    padding-bottom: 1vmin;
    position: relative;
    background-clip: text;
    text-fill: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include semi-bold;

    @include rmin($desktopHD) {
      font-size: 48px; }

    @include md {
      font-size: 4.8vmin;
      line-height: 1.5;
      letter-spacing: 0.08vmin;
      margin-bottom: 2vmin; }

    &:after {
      background-color: #fff;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: calc(var(--line-width) + 4.2vmin); }

    &:before {
      background-color: #fff;
      border-radius: 50%;
      bottom: -1.3px;
      box-shadow: 0 0 10px 4px #ffffff;
      content: '';
      height: 5px;
      position: absolute;
      left: calc(var(--line-width) + 3.9vmin);
      width: 5px; }

    @include md {
      &:before,
      &:after {
        content: none; } } }

  &__text {
    font-size: 3.5vmin;
    line-height: 1.5;
    letter-spacing: 0.06em;
    margin-bottom: 3.75vmin;

    @include md {
      font-size: 4vmin;
      letter-spacing: 0.15vmin; } }

  &__icons {
    align-items: center;
    display: flex;

    @include md {
      justify-content: center; } }

  &__icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 4vmin;
    width: 4vmin;

    &:not(:last-of-type) {
      margin-right: 3.3vmin; } } }
