@import 'style/helpers';

.roadmap {
  $this: &;
  display: flex;
  min-height: auto;
  padding-top: 150px;
  @include padding;

  @include md {
    flex-direction: column; }

  &__side {
    width: 50%;

    @include md {
      width: 100%; } }

  &__headline {
    line-height: 0.8;
    padding-right: 2rem;
    max-width: 45vw;
    @include css-lock(80, 100, $desktop, $desktopHD);

    @include lg {
      @include css-lock(60, 80, $tablet, $desktop); }

    @include md {
      font-size: 52px;
      margin-bottom: 50px;
      max-width: auto;
      padding-left: 2.7vmin; }

    @include sm {
      font-size: 36px; } }

  &__steps {
    position: relative;

    &:before,
    #{$this}__progress {
      content: '';
      left: 0;
      position: absolute;
      transform: translateX(-0.6rem);
      width: 0.6rem;

      @include md {
        width: 30px; } }

    &:before {
      background-image: linear-gradient(to bottom, #ece1ff 70%, rgba(255, 255, 255, 0.21) 100%);
      height: calc(100% - 1.5rem);
      opacity: 0.08;
      top: 0.7rem;

      @include md {
        top: 35px; } } }

  &__progress {
    background-image: linear-gradient(to bottom, rgba(10, 8, 13, 0) 0%, #f419a8 28%, #806df4 84%, #00f1f9 100%); }

  &__item {
    padding-left: 1rem;
    position: relative;

    @include md {
      padding-left: 23px; }

    &:not(:last-of-type) {
      margin-bottom: 1.5rem;

      @include md {
        margin-bottom: 70px; } }

    &:last-of-type {
      #{$this}__title {
        background-image: linear-gradient(87deg, #ff2dd6 -12%, #ff120c 89%);
        background-clip: text;
        text-fill: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }

      #{$this}__text {
        @include md {
          @include semi-bold; } } } }

  &__title {
    display: inline-block;
    line-height: 1.5;
    letter-spacing: 1.52px;
    position: relative;
    @include css-lock(38, 48, $desktop, $desktopHD);
    @include semi-bold;

    @include lg {
      @include css-lock(24, 38, $tablet, $desktop); }

    &:before {
      background-color: #fff;
      bottom: 0;
      content: '';
      height: 2px;
      left: -1.75rem;
      position: absolute;
      width: calc(100% + 2.55rem);

      @include md {
        left: -62px;
        width: calc(100% + 85px); } } }

  &__text {
    line-height: 1.5;
    letter-spacing: 0.95px;
    margin-top: 0.48rem;
    max-width: 705px;
    position: relative;
    @include css-lock(24, 30, $desktop, $desktopHD);

    @include lg {
      @include css-lock(18, 24, $desktop, $desktopHD); }

    @include md {
      line-height: 1.63;
      letter-spacing: 0.51px;
      margin-top: 25px;
      @include css-lock(16, 18, $mob, $tablet); } }

  &__icon {
    display: block;
    left: -1.8rem;
    position: absolute;
    top: 0;
    width: 1rem;

    @include md {
      left: -58px;
      width: 40px; } } }
