@import 'style/helpers';

.about-coin {
	display: flex;
	align-items: center;
	@include padding-sm;
	@include r(1280) {
		display: block; }
	&__content {
		width: 47%;
		padding-right: 1.4rem;
		@include r(1280) {
			width: auto;
			padding: 0;
			margin-bottom: 60px; } }
	&__title {
		@include css-lock(60, 80, $desktop, $desktopHD);
		@include extra-bold;
		background-clip: text;
		text-fill: transparent;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-image: linear-gradient(to right, #00f1f9, #00f93a 52%);
		margin-bottom: 0.2rem;
		@include md {
			text-align: center;
			@include css-lock(42, 54, $mob, $tablet); } }
	&__subtitle {
		line-height: 1.42;
		@include bold;
		@include css-lock(36, 52, $desktop, $desktopHD);
		margin-bottom: 0.2rem;
		@include md {
			text-align: center;
			@include css-lock(30, 34, $mob, $tablet); } }
	&__text {
		line-height: 1.47;
		@include css-lock(24, 30, $desktop, $desktopHD);
		@include md {
			text-align: center;
			@include css-lock(18, 22, $mob, $tablet); } }
	&__items {
		width: 53%;
		display: flex;
		flex-wrap: wrap;
		margin: -15px;
		@include lg {
			width: 60%; }
		@include r(1280) {
			width: auto; } }
	&__item {
		width: 50%;
		padding: 15px;
		@include r(768) {
			width: 100%; } } }
