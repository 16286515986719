@import 'style/helpers';


.details-sidebar {

  &__title {
    padding-left: 20px;
    font-size: 3vmin;
    line-height: 1.47;
    @include bold;
    margin-bottom: 8vmin; }

  &__links {
    border-left: 1px solid #ffffff; }

  &__link {
    position: relative;
    display: block;
    padding: 20px;
    text-decoration: none;
    color: #a1a3b2;
    font-size: 3vmin;
    line-height: 1.47;
    @include lg {
      font-size: 18px; }

    &:focus {
      outline: none; }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -1px;
      width: 3px;
      height: 100%;
      background-color: #21C4FF;
      transition: transform .3s ease-in;
      transform-origin: top;
      transform: scaleY(0); }

    &.is-active {
      @include bold;
      color: #ffffff;

      &:before {
        transform: scaleY(1); } } } }
