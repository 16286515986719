@import 'style/helpers';

.news-about {
	min-height: auto;
	overflow: hidden;
	@include padding-sm;
	&__headline {
		line-height: 1;
		margin-bottom: 2.38rem;
		@include css-lock(80, 100, $desktop, $desktopHD);
		@include md {
			margin-bottom: 4rem;
			@include css-lock(52, 60, $mob, $tablet); }
		@include sm {
			margin-bottom: 2rem; } } }
