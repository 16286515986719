@import 'style/helpers';

.vacancy {
  $this: &;

  background-image: linear-gradient(to bottom, #29232F 0%, #19151F 100%);
  position: relative;
  padding: 45px 40px;
  height: 100%;
  min-height: 200px;
  @include lg {
    min-height: 170px; }
  @include md {
    min-height: 120px; }
  @include r(768) {
    display: flex;
    align-items: center;
    min-height: 80px;
    padding: 15px 30px; }

  &:hover {
    &:before {
      opacity: 1; }

    #{$this}__icon {
      opacity: 1; }

    #{$this}__title {
      color: #000000; } }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(71deg, #00f1f9 -3%, #00f93a 99%);
    opacity: 0;
    transition: opacity .2s ease-in; }

  &__in {
    position: relative;
    z-index: 2; }

  &__icon {
    position: absolute;
    z-index: 2;
    right: 35px;
    bottom: 35px;
    width: 24px;
    opacity: 0;
    transition: opacity .2s ease-in;
    color: #000000;
    @include lg {
      bottom: 24px;
      right: 24px; }
    @include r(768) {
      display: none; } }

  &__title {
    @include bold;
    font-size: 1.6vw;
    line-height: 1.67;
    color: #ffffff;
    transition: color .2s ease-in;
    @include lg {
      font-size: 22px; }
    @include md {
      font-size: 18px; } } }












