@import 'style/helpers';

.subscribe {
  $this: &;

  @include md {
    font-size: 16px; }

  &__field {
    position: relative; }

  &__submit {
    background-image: linear-gradient(199deg, #efb430 87%, #fad155 13%);
    border: none;
    border-radius: 54px;
    height: 0.8rem;
    right: 0;
    position: absolute;
    top: 0;
    width: 0.8rem;

    @include md {
      background-image: none;
      background-color: #efb430;
      border-radius: 0px;
      height: 100%;
      padding: 15px 30px;
      width: 80px; }

    svg {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.4rem;

      @include md {
        width: 23px; } } }

  &__input {
    display: block;
    @include css-lock(20, 24, $desktop, $desktopHD);

    @include lg {
      @include css-lock(16, 20, $desktop, $desktopHD); }

    input {
      background-color: #fff;
      border-radius: 54px;
      border: none;
      display: block;
      height: 0.8rem;
      padding: 0.25rem 0.4rem;
      padding-right: 0.8rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;

      @include md {
        border-radius: 0px;
        padding: 30px 26px; }

      &::placeholder {
        color: rgba(#5b5662, 0.5);
        font-size: inherit; } } }

  &__msg {
    bottom: -0.25rem;
    line-height: 1.25;
    left: 0.4rem;
    position: absolute;
    transform: translateY(100%);
    @include css-lock(20, 24, $desktop, $desktopHD);
    @include medium;

    @include lg {
      @include css-lock(16, 20, $desktop, $desktopHD); } }

  &.is-error {
    color: #fa1640;

    #{$this}__submit {
      background-image: linear-gradient(57deg, #ff238e 14%, #ff120c 85%); }

    input {
      box-shadow: 0 0 42px 5px #fa0d3b; } }

  &.is-success {
    color: #53e61a;

    #{$this}__submit {
      background-image: linear-gradient(58deg, #b2e937 9%, #00e300 83%); } } }
