@import 'style/helpers';

.nav {
  $this: &;
  align-items: center;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include md {
    flex-direction: column;
    font-size: 18px;
    justify-content: center; }

  &__item {
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 0.01rem 0.3rem 0;
    position: relative;
    text-decoration: none;
    transition: color ease-out 0.3s, text-shadow ease-out 0.3s;
    white-space: nowrap;

    @include md {
      color: #88838e; }

    &.is-active {
      cursor: default;
      user-select: none; }

    &:hover,
    &.is-active {
      text-shadow: 0 0 7px rgba(255, 255, 255, 0.75); }

    &:hover {
      & > #{$this}__tooltip {
        opacity: 1;
        visibility: visible; } }

    @include md {
      &:not(:last-child) {
        margin-bottom: 20px; } } }

  &__tooltip {
    opacity: 0;
    visibility: hidden;
    transition: opacity ease-in-out 0.3s, visibility ease-in-out 0.3s; }

  &_dark {
    #{$this}__item {
      color: #5b5662;

      &:hover,
      &.is-active {
        color: #fff; } } } }
