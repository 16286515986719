@import 'style/helpers';

.demo {
  $this: &;
  height: 100vh;

  &__in {
    align-items: flex-end;
    display: flex;
    height: 100%;
    position: relative;
    justify-content: flex-end;
    @include padding;
    padding-bottom: 0;
    padding-top: 0;

    @include md {
      align-items: center;
      flex-direction: column;
      padding: 40px 20px 0;
      justify-content: center; }

    @include md-land {
      align-items: flex-end;
      flex-direction: row;
      justify-content: flex-end; } }

  &__side {
    align-items: flex-end;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;

    &_phone {
      position: static;
      justify-content: flex-end;
      user-select: none;

      @include md-port {
        justify-content: center; } }

    &_content {
      z-index: 3;

      @include md-land {
        margin-right: 5vmin; } } }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center; }

  &__shadow-coin,
  &__coin {
    border-radius: 50%;
    height: 26.6vmin;
    position: absolute;
    left: 50%;
    margin-left: -13.3vmin;
    top: 0;
    width: 26.6vmin;

    @include md-port {
      right: 50%;
      margin-right: -13.3vmin; } }

  &__coin {
    z-index: 1;

    img {
      display: block;
      height: 100%;
      left: 0;
      object-fit: contain;
      top: 0;
      position: absolute;
      width: 100%; } }

  &__shadow-coin {
    box-shadow: 0 0 125px 0 rgba(182, 32, 224, 0.52);
    transform: scale(0.95);
    z-index: 0;

    &:before {
      background-image: radial-gradient(circle at 50% 50%, rgba(94, 89, 183, 0.65), rgba(0, 0, 0, 0) 69%);
      content: '';
      height: 53.9vmin;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 53.9vmin; }

    span {
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 4px 2px #fff;
      display: block;
      left: 0;
      position: absolute;
      top: 0;

      &:before {
        animation: glowing ease-out 5s infinite;
        content: '';
        left: 50%;
        opacity: 0.7;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transform-origin: top left; }

      &:nth-child(1) {
        height: 0.75vmin;
        left: -11vmin;
        top: 32vmin;
        width: 0.75vmin;

        &:before {
          background-image: radial-gradient(circle at 50% 50%, #392f64, rgba(28, 23, 50, 0) 69%);
          height: 16.25vmin;
          mix-blend-mode: lighten;
          width: 16.25vmin; } }

      &:nth-child(2) {
        height: 0.5vmin;
        left: 40vmin;
        top: -0.8vmin;
        width: 0.5vmin;

        &:before {
          animation-duration: 3s;
          background-image: radial-gradient(circle at 50% 50%, #392f64, rgba(28, 23, 50, 0) 62%);
          height: 9vmin;
          mix-blend-mode: lighten;
          width: 9vmin; } } } }

  &__phone {
    height: 81.91vmin;
    width: 65.83vmin;

    img {
      display: block;
      width: 100%; } }

  &__body {
    overflow: hidden;
    position: relative;
    z-index: 1; }

  &__footer {
    bottom: 32px;
    color: #5b5662;
    font-size: 1.5vmin;
    letter-spacing: 0.56px;
    padding-right: 12vmin;
    position: absolute;
    left: 0;
    @include semi-bold;

    @include rmin($desktopHD) {
      font-size: 18px; }

    @include md {
      display: none; } }

  &__dotts {
    bottom: 0;
    height: inherit;
    position: absolute;
    width: inherit; }

  &__dott-item {
    background-color: #924bc8;
    border-radius: 50%;
    position: absolute;

    &:nth-of-type(1) {
      height: 2.8vmin;
      left: 58vmin;
      opacity: 0.3;
      top: 59vmin;
      width: 2.8vmin; }

    &:nth-of-type(2) {
      height: 2.5vmin;
      left: 8.1vmin;
      opacity: 0.5;
      top: 37.1vmin;
      width: 2.5vmin; }

    &:nth-of-type(3) {
      height: 1.3vmin;
      opacity: 0.5;
      right: 8.1vmin;
      top: 50.5vmin;
      width: 1.3vmin; }

    &:nth-of-type(4),
    &:nth-of-type(5) {
      height: 0.8vmin;
      width: 0.8vmin; }

    &:nth-of-type(4) {
      opacity: 0.8;
      right: 11.25vmin;
      top: 54.5vmin; }

    &:nth-of-type(5) {
      left: 11.4vmin;
      top: 31.4vmin; } }

  &__circles {
    position: relative;
    top: calc(50% + 5vmin);
    z-index: -1; } }

@keyframes glowing {
  0%, 100% {
    transform: scale(1) translate(-50%, -50%); }

  33.3% {
    transform: scale(1.2) translate(-50%, -50%); }

  66.6% {
    transform: scale(0.7) translate(-50%, -50%); } }
