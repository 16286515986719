@import 'style/helpers';

.vacancies {
  background-image: linear-gradient(213deg, #000000 0%, #140e1c 23%, #000000 75%);
  position: relative;
  min-height: 100vh;
  @include padding;
  @include md {
    min-height: auto;
    padding: 130px 0 90px; }

  &__in {
    position: relative; }
  //
  //.details
  //  &__header
  //    display: block
  //  &__back
  //    margin-bottom: 4vmin
  //  &__sidebar
 }  //    padding-top: 13vmin
