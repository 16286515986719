@import 'style/helpers';

.filter {
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, transform 0.6s ease-in;
  @include md {
    padding: 0 16px; }

  &__title {
    font-size: 10vmin;
    margin-bottom: 10vmin;
    color: #ffffff;
    @include bold;
    @include md {
      font-size: 30px;
      line-height: 1.5; } }

  &__content {
    display: flex;
    align-items: flex-start;
    @include r(768) {
      display: block; } }

  &__sidebar {
    width: 21vw;
    flex-shrink: 0;
    margin-right: 6.6vw;
    @include md {
      width: 30vw;

      @include r(768) {
        position: relative;
        margin-left: -16px;
        margin-right: -16px;
        width: calc(100% + 32px);
        border-bottom: 2px solid rgba(54, 51, 58, 0.8); } } }

  &__body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1090px;
    @include r(768) {
      margin-top: 40px; } }

  &__cities {
    @include r(768) {
      z-index: 3;
      width: 100%;
      height: 0;
      overflow: hidden;
      position: absolute;
      top: 100%;
      margin-top: 2px;
      left: 0;
      padding: 0 16px;
      background-image: linear-gradient(213deg, #000000 0%, #140e1c 23%, #000000 75%);
      &.is-opened {
        height: auto;
        padding: 16px; } } }

  &__vacancy {
    max-width: 520px;
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 60px;
    cursor: pointer;
    @include md {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      margin-left: auto; }
    @include r(768) {
      margin-bottom: 10px;
      max-width: none; } }

  &__selected {
    display: none;
    @include r(768) {
      @include bold;
      font-size: 18px;
      padding: 0 16px 6px;
      display: flex;
      align-items: center;
      justify-content: space-between; }

    svg {
      @include r(768) {
        width: 18px;
        fill: #21c4ff;
        transition: transform .2s ease-in; }
      @include sm {
        width: 14px; } }

    &.is-opened {
      svg {
        transform: rotate(90deg); } } }

  &__item {
    cursor: pointer;
    padding: 30px 0;
    font-size: 1.6vw;
    @include regular;
    @include lg {
      font-size: 22px; }
    @include md {
      padding: 10px 0;
      line-height: 1.5;
      font-size: 18px; }

    &.is-active {
      @include bold; } }

  &.enter-active {
    opacity: 0;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, transform 0.6s ease-in; }

  &.enter-done {
    opacity: 1; }

  &.enter-active,
  &.enter-done {
    visibility: visible;
    transform: translateX(0); }

  &.exit-active {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%); } }
