.header {
  align-items: center;
  display: flex;
  left: 0;
  padding: 2rem 1.5rem;
  padding-bottom: 40px;
  padding-top: 40px;
  position: fixed;
  top: 0;
  justify-content: space-between;
  width: 100%;
  z-index: 5;
  font-size: 18px;
  font-size: calc(15px + (18 - 15) * ((100vw - 1440px) / (1920 - 1440)));
  font-family: 'Montserrat-SemiBold', Helvetica, sans-serif;
  pointer-events: auto; }
  @media only screen and (max-width: 1024px) {
    .header {
      padding: 100px 46px; } }
  @media (max-width: 1440px) {
    .header {
      font-size: 15px; } }
  @media (min-width: 1920px) {
    .header {
      font-size: 18px; } }
  @media only screen and (max-width: 1440px) {
    .header {
      font-size: 15px;
      font-size: calc(12px + (15 - 12) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .header {
      font-size: 12px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .header {
      font-size: 15px; } }
  @media only screen and (max-width: 1024px) {
    .header {
      font-size: 18px;
      padding: 24px 16px; } }
  .header.is-fixed:before {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .is-mob-menu-opened .header {
    position: fixed;
    top: 0 !important; }
  .header:before {
    background-color: #202022;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: -webkit-transform ease-out 0.3s;
    transition: -webkit-transform ease-out 0.3s;
    transition: transform ease-out 0.3s;
    transition: transform ease-out 0.3s, -webkit-transform ease-out 0.3s;
    width: 100%; }
  .header__logo {
    position: relative;
    width: 134px; }
    @media only screen and (max-width: 1440px) {
      .header__logo {
        width: 120px; } }
  .header__nav {
    padding: 0 20px; }
    @media only screen and (max-width: 1024px) {
      .header__nav {
        display: none; } }
  .header__btns {
    align-items: center;
    display: flex;
    justify-content: center; }
  .header__burger {
    display: none;
    margin-left: 28px;
    z-index: 6; }
    @media only screen and (max-width: 1024px) {
      .header__burger {
        display: block; } }

.logo img {
  display: block;
  width: 100%; }

.tooltip {
  color: #000;
  cursor: default;
  left: 50%;
  line-height: 1.3;
  position: absolute;
  top: calc(100% + 16px);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
  z-index: 2;
  font-size: 16px;
  font-size: calc(14px + (16 - 14) * ((100vw - 1440px) / (1920 - 1440)));
  font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
  @media (max-width: 1440px) {
    .tooltip {
      font-size: 14px; } }
  @media (min-width: 1920px) {
    .tooltip {
      font-size: 16px; } }
  @media only screen and (max-width: 1440px) {
    .tooltip {
      font-size: 14px;
      font-size: calc(12px + (14 - 12) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .tooltip {
      font-size: 12px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .tooltip {
      font-size: 14px; } }
  .tooltip__in {
    background-image: -webkit-gradient(linear, left top, right top, from(#ffeb4f), to(#00e300));
    background-image: linear-gradient(to right, #ffeb4f, #00e300);
    border-radius: 27px;
    padding: 8px 22px; }
    @media only screen and (max-width: 1440px) {
      .tooltip__in {
        padding: 5px 18px; } }
    .tooltip__in:before {
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid #65e620;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: -8px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      z-index: -1; }
      @media only screen and (max-width: 1440px) {
        .tooltip__in:before {
          border-left-width: 14px;
          border-right-width: 14px;
          border-bottom-width: 14px; } }

.nav {
  align-items: center;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media only screen and (max-width: 1024px) {
    .nav {
      flex-direction: column;
      font-size: 18px;
      justify-content: center; } }
  .nav__item {
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 0.01rem 0.3rem 0;
    position: relative;
    text-decoration: none;
    -webkit-transition: color ease-out 0.3s, text-shadow ease-out 0.3s;
    transition: color ease-out 0.3s, text-shadow ease-out 0.3s;
    white-space: nowrap; }
    @media only screen and (max-width: 1024px) {
      .nav__item {
        color: #88838e; } }
    .nav__item.is-active {
      cursor: default;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .nav__item:hover, .nav__item.is-active {
      text-shadow: 0 0 7px rgba(255, 255, 255, 0.75); }
    .nav__item:hover > .nav__tooltip {
      opacity: 1;
      visibility: visible; }
    @media only screen and (max-width: 1024px) {
      .nav__item:not(:last-child) {
        margin-bottom: 20px; } }
  .nav__tooltip {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity ease-in-out 0.3s, visibility ease-in-out 0.3s;
    transition: opacity ease-in-out 0.3s, visibility ease-in-out 0.3s; }
  .nav_dark .nav__item {
    color: #5b5662; }
    .nav_dark .nav__item:hover, .nav_dark .nav__item.is-active {
      color: #fff; }

.lang {
  color: #fff;
  position: relative; }
  .lang__btn {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    z-index: 1; }
  .lang__icon {
    padding-left: 11.5px; }
    .lang__icon svg {
      display: block;
      fill: currentColor;
      -webkit-transition: -webkit-transform ease-in-out 0.3s;
      transition: -webkit-transform ease-in-out 0.3s;
      transition: transform ease-in-out 0.3s;
      transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      width: 11px; }
  .lang__links {
    position: relative; }
    @media only screen and (max-width: 1024px) {
      .lang__links {
        height: 100%;
        left: 0;
        overflow: auto;
        position: fixed;
        top: 0;
        text-align: center;
        width: 100%; } }
  .lang__close {
    display: none;
    z-index: 11;
    position: absolute;
    top: 35px;
    right: 16px;
    width: 24px;
    height: 20px;
    background: transparent;
    border: none;
    border-radius: 0; }
    @media only screen and (max-width: 1024px) {
      .lang__close {
        display: block; } }
    .lang__close:before, .lang__close:after {
      display: block;
      content: '';
      position: absolute;
      top: 40%;
      left: 0;
      border-radius: 2px;
      background-color: #0a050d;
      height: 4px;
      width: 100%; }
    .lang__close:before {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .lang__close:after {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .lang__list {
    background-color: #f8f8fd;
    line-height: 1;
    left: -30px;
    min-width: 160px;
    padding: 35px 30px;
    padding-top: 110px;
    position: absolute;
    top: -58px;
    white-space: nowrap; }
    @media only screen and (max-width: 1024px) {
      .lang__list {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        min-height: calc(1 * 100);
        min-height: calc(var(--vh, 1) * 100);
        left: 0;
        padding: 80px 30px;
        position: fixed;
        top: 0;
        justify-content: center;
        width: 100%;
        z-index: 10; } }
    .lang__list:before {
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(194, 194, 203, 0)), to(#413f44));
      background-image: linear-gradient(to bottom, rgba(194, 194, 203, 0), #413f44);
      bottom: 0;
      content: '';
      height: 20%;
      opacity: 0.32;
      left: 0;
      position: absolute;
      width: 100%; }
      @media only screen and (max-width: 1024px) {
        .lang__list:before {
          height: 50%; } }
    .lang__list:after {
      background-image: -webkit-gradient(linear, left top, right top, from(#ffeb4f), to(#00e300));
      background-image: linear-gradient(to right, #ffeb4f, #00e300);
      bottom: 0;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%; }
  .lang__item {
    position: relative;
    z-index: 1; }
    .lang__item:not(:last-of-type) {
      margin-bottom: 22px; }
    .lang__item:hover .lang__link {
      color: #92929c; }
  .lang__link {
    color: inherit;
    text-decoration: none;
    -webkit-transition: color ease-out 0.3s;
    transition: color ease-out 0.3s; }
    .lang__link.is-active {
      color: #92929c;
      cursor: default;
      pointer-events: none; }
  .lang.is-opened {
    color: #000;
    z-index: 8; }
    @media only screen and (max-width: 1024px) {
      .lang.is-opened .lang__btn {
        display: none; } }
    .lang.is-opened .lang__icon svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.burger {
  background-color: transparent;
  border-radius: 2px;
  border: none;
  color: #fff;
  padding: 0;
  width: 24px; }
  .burger__in {
    background-color: currentColor;
    border-radius: inherit;
    height: 4px; }
  .burger:before, .burger:after {
    background-color: currentColor;
    border-radius: inherit;
    content: '';
    display: block;
    height: 4px;
    position: relative; }
  .burger__in, .burger:before {
    margin-bottom: 4px; }
  .burger.is-active .burger__in {
    opacity: 0; }
  .burger.is-active:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 8px; }
  .burger.is-active:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: -8px; }

@media only screen and (max-width: 1024px) {
  .subscribe {
    font-size: 16px; } }

.subscribe__field {
  position: relative; }

.subscribe__submit {
  background-image: linear-gradient(199deg, #efb430 87%, #fad155 13%);
  border: none;
  border-radius: 54px;
  height: 0.8rem;
  right: 0;
  position: absolute;
  top: 0;
  width: 0.8rem; }
  @media only screen and (max-width: 1024px) {
    .subscribe__submit {
      background-image: none;
      background-color: #efb430;
      border-radius: 0px;
      height: 100%;
      padding: 15px 30px;
      width: 80px; } }
  .subscribe__submit svg {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 0.4rem; }
    @media only screen and (max-width: 1024px) {
      .subscribe__submit svg {
        width: 23px; } }

.subscribe__input {
  display: block;
  font-size: 24px;
  font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (1920 - 1440))); }
  @media (max-width: 1440px) {
    .subscribe__input {
      font-size: 20px; } }
  @media (min-width: 1920px) {
    .subscribe__input {
      font-size: 24px; } }
  @media only screen and (max-width: 1440px) {
    .subscribe__input {
      font-size: 20px;
      font-size: calc(16px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1440px) {
    .subscribe__input {
      font-size: 16px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1920px) {
    .subscribe__input {
      font-size: 20px; } }
  .subscribe__input input {
    background-color: #fff;
    border-radius: 54px;
    border: none;
    display: block;
    height: 0.8rem;
    padding: 0.25rem 0.4rem;
    padding-right: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%; }
    @media only screen and (max-width: 1024px) {
      .subscribe__input input {
        border-radius: 0px;
        padding: 30px 26px; } }
    .subscribe__input input::-webkit-input-placeholder {
      color: rgba(91, 86, 98, 0.5);
      font-size: inherit; }
    .subscribe__input input::-moz-placeholder {
      color: rgba(91, 86, 98, 0.5);
      font-size: inherit; }
    .subscribe__input input:-ms-input-placeholder {
      color: rgba(91, 86, 98, 0.5);
      font-size: inherit; }
    .subscribe__input input::-ms-input-placeholder {
      color: rgba(91, 86, 98, 0.5);
      font-size: inherit; }
    .subscribe__input input::placeholder {
      color: rgba(91, 86, 98, 0.5);
      font-size: inherit; }

.subscribe__msg {
  bottom: -0.25rem;
  line-height: 1.25;
  left: 0.4rem;
  position: absolute;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  font-size: 24px;
  font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (1920 - 1440)));
  font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
  @media (max-width: 1440px) {
    .subscribe__msg {
      font-size: 20px; } }
  @media (min-width: 1920px) {
    .subscribe__msg {
      font-size: 24px; } }
  @media only screen and (max-width: 1440px) {
    .subscribe__msg {
      font-size: 20px;
      font-size: calc(16px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1440px) {
    .subscribe__msg {
      font-size: 16px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1920px) {
    .subscribe__msg {
      font-size: 20px; } }

.subscribe.is-error {
  color: #fa1640; }
  .subscribe.is-error .subscribe__submit {
    background-image: linear-gradient(57deg, #ff238e 14%, #ff120c 85%); }
  .subscribe.is-error input {
    box-shadow: 0 0 42px 5px #fa0d3b; }

.subscribe.is-success {
  color: #53e61a; }
  .subscribe.is-success .subscribe__submit {
    background-image: linear-gradient(58deg, #b2e937 9%, #00e300 83%); }

.btn {
  background-size: 120%;
  background-position: 0%;
  border: none;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  line-height: 1;
  min-width: 3.8rem;
  overflow: hidden;
  padding: 0.25rem 0.15rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  font-size: 30px;
  font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440)));
  font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
  @media (max-width: 1440px) {
    .btn {
      font-size: 24px; } }
  @media (min-width: 1920px) {
    .btn {
      font-size: 30px; } }
  @media only screen and (max-width: 1440px) {
    .btn {
      font-size: 24px;
      font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .btn {
      font-size: 18px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .btn {
      font-size: 24px; } }
  .btn__text {
    position: relative; }
  .btn:before {
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transition: opacity ease-out 0.3s;
    transition: opacity ease-out 0.3s;
    width: 100%; }
  .btn:hover {
    background-position: 100% 0%; }
    .btn:hover:before {
      opacity: 1; }
  .btn_pink {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(15%, #ff2dd6), color-stop(60%, #ff120c));
    background-image: linear-gradient(to right, #ff2dd6 15%, #ff120c 60%); }
    .btn_pink:before {
      background-image: -webkit-gradient(linear, left top, right top, from(#ff120e), to(#ff992d));
      background-image: linear-gradient(to right, #ff120e, #ff992d); }
  .btn_blue {
    background-image: -webkit-gradient(linear, left top, right top, from(#9039f6), to(#00f1f9));
    background-image: linear-gradient(to right, #9039f6, #00f1f9); }
    .btn_blue:before {
      background-image: -webkit-gradient(linear, left top, right top, from(#01eff9), to(#00e215));
      background-image: linear-gradient(to right, #01eff9, #00e215); }
  .btn_green {
    background-image: -webkit-gradient(linear, left top, right top, from(#ffeb4f), to(#00e300));
    background-image: linear-gradient(to right, #ffeb4f, #00e300); }
    .btn_green:before {
      background-image: -webkit-gradient(linear, left top, right top, from(#00e300), to(#01e0e3));
      background-image: linear-gradient(to right, #00e300, #01e0e3); }
  .btn_violet {
    background-image: -webkit-gradient(linear, left top, right top, from(#f419a8), color-stop(50%, #7f6fdb), to(#00f1f9));
    background-image: linear-gradient(to right, #f419a8, #7f6fdb 50%, #00f1f9); }
  .btn_full {
    width: 100%; }

.socials__item {
  background-color: rgba(151, 151, 151, 0.1);
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  -webkit-transition: background-color ease-out 0.3s;
  transition: background-color ease-out 0.3s; }
  .socials__item a {
    position: relative; }
  .socials__item:not(:last-of-type) {
    margin-right: 0.3rem; }
  .socials__item:before {
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transition: opacity ease-in 0.3s;
    transition: opacity ease-in 0.3s;
    width: 100%; }
  .socials__item:hover:before {
    opacity: 1; }
  .socials__item_facebook .socials__icon {
    background-size: auto 40%; }
  .socials__item_facebook:before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4064ad), to(#305297));
    background-image: linear-gradient(to bottom, #4064ad, #305297); }
  .socials__item_linkedin:before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0271ae), to(#046092));
    background-image: linear-gradient(to bottom, #0271ae, #046092); }
  .socials__item_telegram:before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#37a8d9), to(#198cbe));
    background-image: linear-gradient(to bottom, #37a8d9, #198cbe); }
  .socials__item_twitter:before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#01baf7), to(#068bb7));
    background-image: linear-gradient(to bottom, #01baf7, #068bb7); }
  .socials__item_youtube:before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f90200), to(#ca0c0a));
    background-image: linear-gradient(to bottom, #f90200, #ca0c0a); }

.socials__icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% auto;
  display: block;
  height: 0.6rem;
  width: 0.6rem; }

.menu-mob {
  background-image: linear-gradient(200deg, #140e1c 90%, #08060a 36%, #000000 25%, #120e17 7%);
  display: none;
  height: 100vh;
  height: calc(1 * 100);
  height: calc(var(--vh, 1) * 100);
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  pointer-events: auto; }
  @media only screen and (max-width: 1024px) {
    .is-mob-menu-opened .menu-mob {
      display: block; } }
  .menu-mob__in {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
  .menu-mob__body {
    display: flex;
    flex-grow: 1;
    padding: 70px 30px;
    justify-content: center; }
  .menu-mob__footer {
    align-items: center;
    background-color: #201f27;
    display: flex;
    flex-direction: column;
    padding: 36px 10px; }
  .menu-mob__btn {
    margin-bottom: 24px; }

.footer {
  padding: 0.48rem 1.5rem;
  position: relative;
  font-size: 16px;
  font-size: calc(14px + (16 - 14) * ((100vw - 1440px) / (1920 - 1440))); }
  @media (max-width: 1440px) {
    .footer {
      font-size: 14px; } }
  @media (min-width: 1920px) {
    .footer {
      font-size: 16px; } }
  @media only screen and (max-width: 1440px) {
    .footer {
      padding: 0.7rem 1.5rem;
      font-size: 14px;
      font-size: calc(12px + (14 - 12) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .footer {
      font-size: 12px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .footer {
      font-size: 14px; } }
  @media only screen and (max-width: 1024px) {
    .footer {
      padding: 15px; } }
  .footer__in {
    align-items: center;
    display: flex;
    justify-content: space-between; }
  .footer:before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(92, 84, 101, 0.5)), to(rgba(92, 84, 101, 0.5))), -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.27)), color-stop(54%, #ffffff), to(rgba(0, 0, 0, 0)));
    background-image: linear-gradient(to bottom, rgba(92, 84, 101, 0.5), rgba(92, 84, 101, 0.5)), linear-gradient(to right, rgba(0, 0, 0, 0.27) 0%, #ffffff 54%, rgba(0, 0, 0, 0));
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    top: 0;
    width: 100%; }
  .footer__logo {
    width: 170px; }
    @media only screen and (max-width: 1440px) {
      .footer__logo {
        width: 100px; } }
  .footer__link {
    color: #5b5662;
    font-size: 18px;
    text-decoration: none;
    -webkit-transition: color ease-out 0.3s;
    transition: color ease-out 0.3s;
    font-size: 18px;
    font-size: calc(14px + (18 - 14) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-Regular', Helvetica, sans-serif; }
    @media (max-width: 1440px) {
      .footer__link {
        font-size: 14px; } }
    @media (min-width: 1920px) {
      .footer__link {
        font-size: 18px; } }
    @media only screen and (max-width: 1440px) {
      .footer__link {
        font-size: 14px;
        font-size: calc(12px + (14 - 12) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .footer__link {
      font-size: 12px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .footer__link {
      font-size: 14px; } }
    .footer__link:not(:last-of-type) {
      margin-right: 0.5rem; }
      @media only screen and (max-width: 1024px) {
        .footer__link:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: 0.1rem; } }
    .footer__link:hover {
      color: #ffffff; }
  .footer__text {
    color: #5b5662;
    display: none;
    font-size: 11px;
    line-height: 1.55;
    text-align: center;
    font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
    @media only screen and (max-width: 1024px) {
      .footer__text {
        display: block;
        margin: auto;
        max-width: 280px; } }
  @media only screen and (max-width: 1024px) {
    .footer__logo, .footer__nav {
      display: none; } }
  @media only screen and (max-width: 1024px) {
    .footer__links {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      text-align: center;
      justify-content: center;
      width: 100%; } }

.hero {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 0;
  padding: 2rem 1.5rem;
  padding-top: 26.5vmin; }
  @media only screen and (max-width: 1024px) {
    .hero {
      padding: 100px 46px; } }
  @media only screen and (max-width: 414px) {
    .hero {
      padding-bottom: 80px;
      padding-top: 80px; } }
  .hero__block {
    max-width: 60%; }
    @media only screen and (max-width: 1024px) {
      .hero__block {
        margin: auto;
        max-width: 540px;
        text-align: center; } }
  .hero__particle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }
  .hero__title {
    font-size: 112px;
    font-size: calc(88px + (112 - 88) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .hero__title {
        font-size: 88px; } }
    @media (min-width: 1920px) {
      .hero__title {
        font-size: 112px; } }
    @media only screen and (max-width: 1440px) {
      .hero__title {
        font-size: 88px;
        font-size: calc(64px + (88 - 64) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .hero__title {
      font-size: 64px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .hero__title {
      font-size: 88px; } }
    @media only screen and (max-width: 1024px) {
      .hero__title {
        line-height: 1.46;
        font-size: 64px;
        font-size: calc(48px + (64 - 48) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .hero__title {
      font-size: 48px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .hero__title {
      font-size: 64px; } }
    @media only screen and (max-width: 414px) {
      .hero__title {
        font-size: 36px; } }
  .hero__subtitle {
    letter-spacing: 0.016em;
    position: relative;
    top: -15px;
    font-size: 60px;
    font-size: calc(48px + (60 - 48) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
    @media (max-width: 1440px) {
      .hero__subtitle {
        font-size: 48px; } }
    @media (min-width: 1920px) {
      .hero__subtitle {
        font-size: 60px; } }
    @media only screen and (max-width: 1440px) {
      .hero__subtitle {
        font-size: 48px;
        font-size: calc(36px + (48 - 36) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .hero__subtitle {
      font-size: 36px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .hero__subtitle {
      font-size: 48px; } }
    @media only screen and (max-width: 1024px) {
      .hero__subtitle {
        line-height: 1.46;
        font-size: 36px;
        font-size: calc(24px + (36 - 24) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .hero__subtitle {
      font-size: 24px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .hero__subtitle {
      font-size: 36px; } }
    @media only screen and (max-width: 414px) {
      .hero__subtitle {
        font-size: 18px;
        margin-bottom: 20px;
        top: 0; } }
  .hero__text {
    line-height: 1.47;
    margin-bottom: 0.8rem;
    max-width: 600px;
    font-size: 30px;
    font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .hero__text {
        font-size: 24px; } }
    @media (min-width: 1920px) {
      .hero__text {
        font-size: 30px; } }
    @media only screen and (max-width: 1024px) {
      .hero__text {
        letter-spacing: -0.18px;
        line-height: 1.63;
        margin-bottom: 60px;
        opacity: 0.59;
        font-size: 24px;
        font-size: calc(16px + (24 - 16) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .hero__text {
      font-size: 16px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .hero__text {
      font-size: 24px; } }
    @media only screen and (max-width: 414px) {
      .hero__text {
        font-size: 14px;
        margin-bottom: 30px; } }
  .hero__coin {
    width: 43%; }
    @media only screen and (max-width: 1024px) {
      .hero__coin {
        display: none; } }
    .hero__coin svg {
      display: block;
      margin: auto;
      max-width: 675px;
      width: 100%; }
      .hero__coin svg #c stop:nth-child(1) {
        -webkit-animation: colors 6s infinite ease-in-out;
                animation: colors 6s infinite ease-in-out; }
      .hero__coin svg #c stop:nth-child(2) {
        -webkit-animation: colors 6s infinite 6s ease-in-out;
                animation: colors 6s infinite 6s ease-in-out; }

@-webkit-keyframes colors {
  20% {
    stop-color: #4C00DD; }
  60% {
    stop-color: #9527CC; }
  90% {
    stop-color: #4C00DD; } }

@keyframes colors {
  20% {
    stop-color: #4C00DD; }
  60% {
    stop-color: #9527CC; }
  90% {
    stop-color: #4C00DD; } }

.text-block {
  font-size: 52px;
  line-height: 1.48;
  letter-spacing: 0.031em;
  min-height: auto;
  text-align: center;
  font-size: 52px;
  font-size: calc(42px + (52 - 42) * ((100vw - 1440px) / (1920 - 1440)));
  padding: 2rem 1.5rem;
  padding-top: 0.8rem;
  font-family: 'Montserrat-Light', Helvetica, sans-serif; }
  @media (max-width: 1440px) {
    .text-block {
      font-size: 42px; } }
  @media (min-width: 1920px) {
    .text-block {
      font-size: 52px; } }
  @media only screen and (max-width: 1024px) {
    .text-block {
      padding: 100px 46px; } }
  @media only screen and (max-width: 1440px) {
    .text-block {
      font-size: 42px;
      font-size: calc(36px + (42 - 36) * ((100vw - 1440px) / (1920 - 1440))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1440px) {
    .text-block {
      font-size: 36px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1920px) {
    .text-block {
      font-size: 42px; } }
  @media only screen and (max-width: 1024px) {
    .text-block {
      padding-bottom: 50px;
      padding-top: 50px;
      font-size: 36px;
      font-size: calc(18px + (36 - 18) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .text-block {
      font-size: 18px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .text-block {
      font-size: 36px; } }
  .text-block__in {
    margin: auto;
    max-width: 1174px; }
    @media only screen and (max-width: 1440px) {
      .text-block__in {
        max-width: 820px; } }
  .text-block p:not(:last-of-type) {
    margin-bottom: 20px; }

.demo {
  height: 100vh; }
  .demo__in {
    align-items: flex-end;
    display: flex;
    height: 100%;
    position: relative;
    justify-content: flex-end;
    padding: 2rem 1.5rem;
    padding-bottom: 0;
    padding-top: 0; }
    @media only screen and (max-width: 1024px) {
      .demo__in {
        padding: 100px 46px; } }
    @media only screen and (max-width: 1024px) {
      .demo__in {
        align-items: center;
        flex-direction: column;
        padding: 40px 20px 0;
        justify-content: center; } }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      .demo__in {
        align-items: flex-end;
        flex-direction: row;
        justify-content: flex-end; } }
  .demo__side {
    align-items: flex-end;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%; }
    .demo__side_phone {
      position: static;
      justify-content: flex-end;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      @media only screen and (max-width: 1024px) and (orientation: portrait) {
        .demo__side_phone {
          justify-content: center; } }
    .demo__side_content {
      z-index: 3; }
      @media only screen and (max-width: 1024px) and (orientation: landscape) {
        .demo__side_content {
          margin-right: 5vmin; } }
  .demo__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .demo__shadow-coin, .demo__coin {
    border-radius: 50%;
    height: 26.6vmin;
    position: absolute;
    left: 50%;
    margin-left: -13.3vmin;
    top: 0;
    width: 26.6vmin; }
    @media only screen and (max-width: 1024px) and (orientation: portrait) {
      .demo__shadow-coin, .demo__coin {
        right: 50%;
        margin-right: -13.3vmin; } }
  .demo__coin {
    z-index: 1; }
    .demo__coin img {
      display: block;
      height: 100%;
      left: 0;
      object-fit: contain;
      top: 0;
      position: absolute;
      width: 100%; }
  .demo__shadow-coin {
    box-shadow: 0 0 125px 0 rgba(182, 32, 224, 0.52);
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    z-index: 0; }
    .demo__shadow-coin:before {
      background-image: radial-gradient(circle at 50% 50%, rgba(94, 89, 183, 0.65), rgba(0, 0, 0, 0) 69%);
      content: '';
      height: 53.9vmin;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 53.9vmin; }
    .demo__shadow-coin span {
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 4px 2px #fff;
      display: block;
      left: 0;
      position: absolute;
      top: 0; }
      .demo__shadow-coin span:before {
        -webkit-animation: glowing ease-out 5s infinite;
                animation: glowing ease-out 5s infinite;
        content: '';
        left: 50%;
        opacity: 0.7;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        -webkit-transform-origin: top left;
                transform-origin: top left; }
      .demo__shadow-coin span:nth-child(1) {
        height: 0.75vmin;
        left: -11vmin;
        top: 32vmin;
        width: 0.75vmin; }
        .demo__shadow-coin span:nth-child(1):before {
          background-image: radial-gradient(circle at 50% 50%, #392f64, rgba(28, 23, 50, 0) 69%);
          height: 16.25vmin;
          mix-blend-mode: lighten;
          width: 16.25vmin; }
      .demo__shadow-coin span:nth-child(2) {
        height: 0.5vmin;
        left: 40vmin;
        top: -0.8vmin;
        width: 0.5vmin; }
        .demo__shadow-coin span:nth-child(2):before {
          -webkit-animation-duration: 3s;
                  animation-duration: 3s;
          background-image: radial-gradient(circle at 50% 50%, #392f64, rgba(28, 23, 50, 0) 62%);
          height: 9vmin;
          mix-blend-mode: lighten;
          width: 9vmin; }
  .demo__phone {
    height: 81.91vmin;
    width: 65.83vmin; }
    .demo__phone img {
      display: block;
      width: 100%; }
  .demo__body {
    overflow: hidden;
    position: relative;
    z-index: 1; }
  .demo__footer {
    bottom: 32px;
    color: #5b5662;
    font-size: 1.5vmin;
    letter-spacing: 0.56px;
    padding-right: 12vmin;
    position: absolute;
    left: 0;
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
    @media only screen and (min-width: 1920px) {
      .demo__footer {
        font-size: 18px; } }
    @media only screen and (max-width: 1024px) {
      .demo__footer {
        display: none; } }
  .demo__dotts {
    bottom: 0;
    height: inherit;
    position: absolute;
    width: inherit; }
  .demo__dott-item {
    background-color: #924bc8;
    border-radius: 50%;
    position: absolute; }
    .demo__dott-item:nth-of-type(1) {
      height: 2.8vmin;
      left: 58vmin;
      opacity: 0.3;
      top: 59vmin;
      width: 2.8vmin; }
    .demo__dott-item:nth-of-type(2) {
      height: 2.5vmin;
      left: 8.1vmin;
      opacity: 0.5;
      top: 37.1vmin;
      width: 2.5vmin; }
    .demo__dott-item:nth-of-type(3) {
      height: 1.3vmin;
      opacity: 0.5;
      right: 8.1vmin;
      top: 50.5vmin;
      width: 1.3vmin; }
    .demo__dott-item:nth-of-type(4), .demo__dott-item:nth-of-type(5) {
      height: 0.8vmin;
      width: 0.8vmin; }
    .demo__dott-item:nth-of-type(4) {
      opacity: 0.8;
      right: 11.25vmin;
      top: 54.5vmin; }
    .demo__dott-item:nth-of-type(5) {
      left: 11.4vmin;
      top: 31.4vmin; }
  .demo__circles {
    position: relative;
    top: calc(50% + 5vmin);
    z-index: -1; }

@-webkit-keyframes glowing {
  0%, 100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%); }
  33.3% {
    -webkit-transform: scale(1.2) translate(-50%, -50%);
            transform: scale(1.2) translate(-50%, -50%); }
  66.6% {
    -webkit-transform: scale(0.7) translate(-50%, -50%);
            transform: scale(0.7) translate(-50%, -50%); } }

@keyframes glowing {
  0%, 100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%); }
  33.3% {
    -webkit-transform: scale(1.2) translate(-50%, -50%);
            transform: scale(1.2) translate(-50%, -50%); }
  66.6% {
    -webkit-transform: scale(0.7) translate(-50%, -50%);
            transform: scale(0.7) translate(-50%, -50%); } }

.circles {
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity linear 0.3s, visibility linear 0.3s;
  transition: opacity linear 0.3s, visibility linear 0.3s;
  visibility: visible; }
  .circles span {
    border: 0.3vmin solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    -webkit-transition: -webkit-transform linear 0.6s;
    transition: -webkit-transform linear 0.6s;
    transition: transform linear 0.6s;
    transition: transform linear 0.6s, -webkit-transform linear 0.6s; }
    .circles span:nth-child(1) {
      height: 35vmin;
      width: 35vmin; }
    .circles span:nth-child(2) {
      height: 63vmin;
      width: 63vmin; }
    .circles span:nth-child(3) {
      height: 91vmin;
      width: 91vmin; }
    .circles span:nth-child(4) {
      height: 119vmin;
      width: 119vmin; }
    .circles span:nth-child(5) {
      height: 170vmin;
      width: 170vmin; }
  .is-circles-animating .circles span {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1); }
  .is-circles-hidden .circles {
    opacity: 0;
    visibility: hidden; }

.item {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  opacity: 0;
  padding-right: 12vmin;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  justify-content: center; }
  .item:nth-of-type(6n + 1) {
    -webkit-transform: translateY(-1.1vmin);
            transform: translateY(-1.1vmin); }
    .item:nth-of-type(6n + 1) .item__title {
      background-image: -webkit-gradient(linear, left top, right top, from(#00f1f9), color-stop(52%, #00f93a));
      background-image: linear-gradient(to right, #00f1f9, #00f93a 52%); }
  .item:nth-of-type(6n + 2) {
    -webkit-transform: translateY(7vmin);
            transform: translateY(7vmin); }
    .item:nth-of-type(6n + 2) .item__title {
      background-image: -webkit-gradient(linear, left top, right top, from(#ffeb4f), color-stop(93%, #00e300));
      background-image: linear-gradient(to right, #ffeb4f, #00e300 93%); }
  .item:nth-of-type(6n + 3) {
    -webkit-transform: translateY(3vmin);
            transform: translateY(3vmin); }
    .item:nth-of-type(6n + 3) .item__title {
      background-image: -webkit-gradient(linear, left top, right top, from(#7f6fdb), color-stop(94%, #00f1f9));
      background-image: linear-gradient(to right, #7f6fdb, #00f1f9 94%); }
  .item:nth-of-type(6n + 4) {
    -webkit-transform: translateY(7vmin);
            transform: translateY(7vmin); }
    .item:nth-of-type(6n + 4) .item__title {
      background-image: linear-gradient(79deg, #f12d2d -8%, #e1f701 91%); }
  .item:nth-of-type(6n + 5) {
    -webkit-transform: translateY(-3vmin);
            transform: translateY(-3vmin); }
    .item:nth-of-type(6n + 5) .item__title {
      background-image: linear-gradient(84deg, #f419a8 -4%, #7f6fdb 52%); }
  .item:nth-of-type(6n) .item__title {
    background-image: linear-gradient(82deg, #ff2dd6 -19%, #ff120c 80%); }
  @media only screen and (max-width: 1024px) {
    .item {
      padding-top: 60px;
      padding-right: 0;
      padding-bottom: 40px;
      text-align: center;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      justify-content: flex-start; }
      .item:nth-of-type(n), .item:nth-of-type(2n) {
        -webkit-transform: translateY(0);
                transform: translateY(0); } }
  .item__title {
    font-size: 4vmin;
    line-height: 1.48;
    letter-spacing: 1.52px;
    margin-bottom: 45px;
    padding-bottom: 1vmin;
    position: relative;
    background-clip: text;
    text-fill: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
    @media only screen and (min-width: 1920px) {
      .item__title {
        font-size: 48px; } }
    @media only screen and (max-width: 1024px) {
      .item__title {
        font-size: 4.8vmin;
        line-height: 1.5;
        letter-spacing: 0.08vmin;
        margin-bottom: 2vmin; } }
    .item__title:after {
      background-color: #fff;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: calc(var(--line-width) + 4.2vmin); }
    .item__title:before {
      background-color: #fff;
      border-radius: 50%;
      bottom: -1.3px;
      box-shadow: 0 0 10px 4px #ffffff;
      content: '';
      height: 5px;
      position: absolute;
      left: calc(var(--line-width) + 3.9vmin);
      width: 5px; }
    @media only screen and (max-width: 1024px) {
      .item__title:before, .item__title:after {
        content: none; } }
  .item__text {
    font-size: 3.5vmin;
    line-height: 1.5;
    letter-spacing: 0.06em;
    margin-bottom: 3.75vmin; }
    @media only screen and (max-width: 1024px) {
      .item__text {
        font-size: 4vmin;
        letter-spacing: 0.15vmin; } }
  .item__icons {
    align-items: center;
    display: flex; }
    @media only screen and (max-width: 1024px) {
      .item__icons {
        justify-content: center; } }
  .item__icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 4vmin;
    width: 4vmin; }
    .item__icon:not(:last-of-type) {
      margin-right: 3.3vmin; }

.screen {
  background-image: linear-gradient(144deg, #2f2c38 8%, #000000 109%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 79.6vmin;
  overflow: hidden;
  position: absolute;
  right: 4vmin;
  width: 58vmin;
  z-index: -1; }
  @media only screen and (max-width: 1024px) {
    .screen {
      right: 50%;
      margin-right: -29vmin; } }
  .screen__bar {
    background-image: url("/img/demo/phone-bar.svg");
    background-size: 85% auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 2vmin;
    position: relative;
    top: 2vmin;
    width: 100%;
    z-index: 1; }
  .screen__circle {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(134, 133, 140, 0.3)), to(#86858c));
    background-image: linear-gradient(to bottom, rgba(134, 133, 140, 0.3), #86858c);
    border-radius: 50%;
    height: 42vmin;
    left: 50%;
    margin-left: -21vmin;
    margin-top: -22vmin;
    opacity: 0.14;
    position: absolute;
    top: 50%;
    width: 42vmin; }
    .screen__circle_back {
      background-image: linear-gradient(144deg, #4D128B 0%, #9C53B7 100%);
      height: 100%;
      top: 0;
      left: -19%;
      margin-left: 0;
      margin-top: 0;
      opacity: 1;
      width: 79.6vmin; }
  .screen__headline {
    display: flex;
    font-size: 2.5vmin;
    flex-grow: 1;
    letter-spacing: 0.07vmin;
    line-height: 1.5;
    min-height: 9vmin;
    padding: 5.58vmin 3vmin 0;
    position: relative;
    justify-content: space-between;
    z-index: 2;
    font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
    .is-sixth-screen .screen__headline {
      background-image: url("/img/demo/input.svg");
      background-position: top 5vmin center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 90% auto; }
      .is-sixth-screen .screen__headline .screen__btn, .is-sixth-screen .screen__headline .screen__title, .is-sixth-screen .screen__headline .screen__avatar {
        opacity: 0;
        visibility: hidden; }
  .screen__title {
    height: 1.2em;
    overflow: hidden;
    text-align: center;
    white-space: nowrap; }
    .screen__title span {
      display: block; }
    .is-third-screen .screen__title span {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
    .is-seventh-screen .screen__title span {
      -webkit-transform: translateY(-200%);
              transform: translateY(-200%); }
  .screen__footer {
    align-items: center;
    display: flex;
    position: relative;
    justify-content: space-between; }
  .screen__btn, .screen__avatar {
    background-size: contain;
    background-repeat: no-repeat; }
  .screen__btn {
    background-image: url("/img/demo/arrow-left.svg");
    height: 3vmin;
    padding-right: 2.5vmin;
    width: 1.5vmin; }
  .screen__avatar {
    background-image: url("/img/demo/avatar.svg");
    height: 4vmin;
    position: relative;
    width: 4vmin; }
    .screen__avatar:before {
      background-image: url("/img/demo/avatar-colored.svg");
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      -webkit-transition: opacity ease-out 0.3s, visibility ease-out 0.3s;
      transition: opacity ease-out 0.3s, visibility ease-out 0.3s;
      visibility: hidden;
      width: 100%; }
    .screen__avatar.is-colored:before {
      opacity: 1;
      visibility: visible; }
  .screen__curtain {
    background-color: #3c176b;
    height: 14vmin;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }
  .screen__balance {
    height: 100%;
    left: 0;
    position: absolute;
    top: 63vmin;
    -webkit-transform: translateY(-18%);
            transform: translateY(-18%);
    width: 100%; }
    .screen__balance:before, .screen__balance:after {
      background-size: 97% auto;
      background-repeat: no-repeat;
      background-position: bottom center;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      -webkit-transition: opacity ease-out 0.3s;
      transition: opacity ease-out 0.3s;
      width: 100%; }
    .is-first-screen .screen__balance:before {
      background-image: url("/img/demo/screen-1.svg");
      background-size: 100% auto;
      background-position: bottom center;
      opacity: 1; }
    .is-first-screen .screen__balance:after {
      opacity: 0; }
    .is-first-screen .screen__balance .screen__item_first {
      opacity: 1;
      visibility: visible; }
    .is-second-screen .screen__balance:before {
      opacity: 0; }
    .is-second-screen .screen__balance:after {
      background-image: url("/img/demo/screen-2.svg");
      background-size: 99% auto;
      background-position: bottom center;
      opacity: 1; }
    .is-second-screen .screen__balance .screen__avatar_colored:before {
      opacity: 1; }
    .is-second-screen .screen__balance .screen__item_second {
      opacity: 1;
      visibility: visible; }
    .is-third-screen .screen__balance:before {
      background-image: none;
      background-size: cover;
      opacity: 1; }
    .is-third-screen .screen__balance:after {
      opacity: 0; }
    .is-third-screen .screen__balance .screen__item_third {
      opacity: 1;
      visibility: visible; }
    .is-fourth-screen .screen__balance:before {
      opacity: 0; }
    .is-fourth-screen .screen__balance:after {
      background-image: url("/img/demo/screen-4.svg");
      background-size: cover;
      opacity: 1; }
    .is-fourth-screen .screen__balance .screen__item_sixth {
      opacity: 1;
      visibility: visible; }
    .is-fifth-screen .screen__balance {
      background-image: url("/img/demo/screen-5.svg");
      background-size: 101% auto;
      background-position: bottom center; }
      .is-fifth-screen .screen__balance .screen__item_last {
        opacity: 1;
        visibility: visible; }
  .screen__item {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 1; }
    .screen__item_last {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      opacity: 0;
      padding-top: 13vmin;
      text-align: center;
      visibility: hidden;
      justify-content: center; }
  .screen__price, .screen__market-cap, .screen__eex, .screen__eex-title, .screen__status, .screen__eex-status-title, .screen__btc, .screen__eex-btn, .screen__eex-price, .screen__send-money-btn, .screen__send-money-cancel, .screen__done-headline, .screen__done-status, .screen__done-btn, .screen__done-ok, .screen__eex-price-title, .screen__market-cap-title, .screen__date {
    display: inline-block;
    position: absolute;
    font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
  .screen__price {
    font-size: 3vmin;
    right: 7vmin;
    top: 21vmin; }
  .screen__market-cap {
    font-size: 2.6vmin;
    left: 5vmin;
    top: 42.5vmin; }
  .screen__eex {
    font-size: 4.6vmin;
    line-height: 1.26;
    letter-spacing: 0.08vmin;
    left: 3.58vmin;
    top: 22.85vmin; }
  .screen__eex-title {
    font-size: 3vmin;
    left: 3.2vmin;
    opacity: 0.6;
    top: 19.3vmin;
    font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
  .screen__status {
    width: 100%; }
  .screen__eex-status-title {
    font-size: 3vmin;
    left: 5.4vmin;
    letter-spacing: 0.03vmin;
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
    .screen__eex-status-title_first {
      top: 34.3vmin; }
    .screen__eex-status-title_second {
      top: 50.3vmin; }
    .screen__eex-status-title_third {
      top: 66.3vmin; }
  .screen__btc {
    color: #5b5662;
    font-size: 2vmin;
    letter-spacing: 0.0275vmin;
    right: 12.7vmin;
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
    .screen__btc_first {
      top: 36.8vmin; }
    .screen__btc_second {
      top: 60.8vmin; }
  .screen__status {
    padding: 0 8.2vmin 0 5.5vmin; }
    .screen__status_first, .screen__status_second, .screen__status_third {
      display: flex;
      justify-content: space-between; }
      .screen__status_first span:nth-child(1), .screen__status_second span:nth-child(1), .screen__status_third span:nth-child(1) {
        font-size: 1.83vmin;
        letter-spacing: 0.03vmin;
        opacity: 0.61;
        font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
      .screen__status_first span:nth-child(2), .screen__status_second span:nth-child(2), .screen__status_third span:nth-child(2) {
        color: #290f4b;
        font-size: 1.5vmin;
        letter-spacing: 0.0875vmin;
        text-transform: uppercase; }
    .screen__status_first {
      top: 39.4vmin; }
    .screen__status_second {
      top: 55.5vmin; }
    .screen__status_third {
      top: 71.5vmin; }
  .screen__price, .screen__market-cap {
    color: #ffe08c; }
  .screen__eex {
    color: #ffffff; }
  .screen__eex-btn {
    font-size: 2.16vmin;
    letter-spacing: 0.0325vmin;
    text-transform: uppercase; }
    .screen__eex-btn_buy {
      right: 12.6vmin;
      top: 45.5vmin; }
    .screen__eex-btn_sell {
      right: 12.2vmin;
      top: 69.8vmin; }
  .screen__eex-price {
    font-size: 2vmin;
    left: 5.6vmin;
    letter-spacing: 0.03vmin;
    font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
    .screen__eex-price_first {
      top: 45.55vmin; }
    .screen__eex-price_second {
      top: 69.75vmin; }
  .screen__logo {
    height: 20vmin;
    margin: 0 auto 8.3vmin;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    width: 20vmin; }
    .screen__logo:before, .screen__logo:after {
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      -webkit-transition: opacity ease-out 0.3s, visibility ease-out 0.3s;
      transition: opacity ease-out 0.3s, visibility ease-out 0.3s;
      width: 100%; }
    .screen__logo:before {
      background-image: url("/img/demo/fish.svg"); }
    .screen__logo:after {
      background-image: url("/img/demo/dragon.png");
      -webkit-transform: translateX(1.5vmin);
              transform: translateX(1.5vmin);
      opacity: 0;
      visibility: hidden; }
    .screen__logo.is-updated:before {
      opacity: 0;
      visibility: hidden; }
    .screen__logo.is-updated:after {
      opacity: 1;
      visibility: visible; }
  .screen__line {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 1.6vmin;
    height: 1.3vmin;
    overflow: hidden;
    position: relative;
    width: 40vmin; }
  .screen__progress {
    background-image: -webkit-gradient(linear, left top, right top, from(#ffeb4f), to(#00e300));
    background-image: linear-gradient(to right, #ffeb4f, #00e300);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .screen__status-title {
    font-size: 3.5vmin;
    line-height: 1.69;
    letter-spacing: 0.47px;
    margin-bottom: 5.6vmin;
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
  .screen__points-title {
    font-size: 1.83vmin;
    line-height: 3;
    opacity: 0.6; }
  .screen__point {
    font-size: 4.3vmin;
    line-height: 1.37;
    letter-spacing: 0.93px;
    margin-bottom: 3vmin;
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
  .screen__search-footer {
    align-items: center;
    background-color: rgba(50, 8, 103, 0.5);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 15vmin;
    left: 0;
    position: absolute;
    text-align: center;
    justify-content: center;
    width: 100%; }
    .screen__search-footer span {
      display: block; }
      .screen__search-footer span:first-child {
        font-size: 3.6vmin;
        margin-bottom: 1.5vmin;
        font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
      .screen__search-footer span:last-child {
        font-size: 2.1vmin;
        opacity: 0.6; }
  .screen__search-icon {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.02)), to(rgba(255, 255, 255, 0.05)));
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.05) 100%);
    border-radius: 50%;
    height: 16.6vmin;
    left: 50%;
    position: absolute;
    top: 59%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 16.6vmin; }
    .screen__search-icon:before, .screen__search-icon:after {
      border: 0.25vmin solid rgba(255, 255, 255, 0.15);
      border-radius: 50%;
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .screen__search-icon:before {
      height: 33.3vmin;
      width: 33.3vmin; }
    .screen__search-icon:after {
      height: 55vmin;
      width: 55vmin; }
    .screen__search-icon svg {
      display: block;
      left: 56%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 6.25vmin; }
  .screen__user {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 8.3vmin;
    position: absolute;
    width: 8.3vmin; }
    .screen__user span {
      bottom: -2vmin;
      font-size: 2vmin;
      line-height: 1.3;
      left: 50%;
      position: absolute;
      text-align: center;
      -webkit-transform: translate(-50%, 100%);
              transform: translate(-50%, 100%);
      min-width: 100%;
      font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
    .screen__user_first {
      background-image: url("/img/demo/user-1.svg");
      left: 6vmin;
      top: 23vmin; }
    .screen__user_second {
      background-image: url("/img/demo/user-2.svg");
      right: 8vmin;
      top: 44vmin; }
  .screen__send-money, .screen__done {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .screen__send-money svg, .screen__done svg {
      display: block;
      width: 100%; }
  .screen__circles {
    left: 50%;
    position: absolute;
    top: calc(50% + 5.5vmin); }
  .screen__send-money-btn {
    bottom: 7.6vmin;
    font-size: 2.1vmin;
    left: 50%;
    letter-spacing: 0.1vmin;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-transform: uppercase;
    text-shadow: 0 1px 10px rgba(0, 0, 0, 0.32);
    font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
  .screen__send-money-cancel {
    bottom: 2vmin;
    font-size: 2vmin;
    left: 50%;
    letter-spacing: 0.001vmin;
    opacity: 0.6;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
  .screen__done-headline, .screen__done-status, .screen__done-btn, .screen__done-ok {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .screen__done-headline {
    font-size: 3vmin;
    letter-spacing: 0.1vmin;
    opacity: 0.6;
    top: 18.2vmin;
    font-family: 'Montserrat-Light', Helvetica, sans-serif; }
  .screen__done-status {
    font-size: 2.4vmin;
    letter-spacing: 0.1vmin;
    top: 53vmin; }
  .screen__done-btn {
    bottom: 10.4vmin;
    font-size: 2.2vmin;
    letter-spacing: 0.01vmin;
    text-transform: uppercase; }
  .screen__done-ok {
    bottom: 5vmin;
    font-size: 2vmin;
    opacity: 0.6;
    font-family: 'Montserrat-Light', Helvetica, sans-serif; }
  .screen__eex-price-title, .screen__market-cap-title {
    font-size: 3vmin;
    left: 5.3vmin;
    letter-spacing: 0.03vmin;
    top: 21vmin; }
  .screen__market-cap-title {
    top: 37.3vmin; }
  .screen__date {
    bottom: 27vmin;
    font-size: 2vmin;
    display: flex;
    padding: 0 10vmin;
    justify-content: space-between;
    width: 100%;
    font-family: 'Montserrat-Light', Helvetica, sans-serif; }
    .screen__date span {
      display: block; }
  .is-es .screen .screen__status {
    padding-right: 7.5vmin; }
  .is-es .screen .screen__eex-btn_sell {
    right: 10.2vmin; }
  .is-es .screen .screen__eex-btn_buy {
    right: 9vmin; }
  .is-es .screen .screen__eex-price_first {
    top: 45.8vmin; }
  .is-es .screen .screen__eex-price_second {
    top: 70.2vmin; }
  .is-es .screen .screen__btc_first {
    top: 37vmin; }
  .is-es .screen .screen__btc_second {
    top: 61.3vmin; }
  .is-es .screen .screen__date {
    bottom: 26vmin;
    text-align: center; }
  .is-pt .screen .screen__status {
    padding-right: 7.2vmin; }
  .is-pt .screen .screen__eex-btn_sell {
    right: 10vmin; }
  .is-pt .screen .screen__eex-btn_buy {
    right: 9vmin; }
  .is-pt .screen .screen__btc {
    width: 9vmin; }
    .is-pt .screen .screen__btc_first {
      top: 36vmin; }
    .is-pt .screen .screen__btc_second {
      top: 60.2vmin; }
  .is-pt .screen .screen__date {
    bottom: 26vmin;
    text-align: center; }
  .is-jp .screen .screen__status {
    padding-right: 10vmin; }
    .is-jp .screen .screen__status_first {
      padding-right: 11.5vmin; }
  .is-jp .screen .screen__eex-btn_sell {
    right: 12.6vmin; }
  .is-jp .screen .screen__btc_first {
    top: 37vmin; }
  .is-jp .screen .screen__btc_second {
    top: 61.2vmin; }
  .is-kr .screen .screen__status {
    padding-right: 11.4vmin; }
    .is-kr .screen .screen__status_first {
      padding-right: 11.6vmin; }
  .is-kr .screen .screen__eex-btn {
    right: 13vmin; }
  .is-ru .screen .screen__status_second, .is-ru .screen .screen__status_third {
    padding-right: 5.8vmin; }
  .is-ru .screen .screen__eex-btn_buy {
    right: 10vmin; }
  .is-ru .screen .screen__eex-btn_sell {
    right: 9vmin; }

.video {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 2rem 1.5rem; }
  @media only screen and (max-width: 1024px) {
    .video {
      padding: 100px 46px; } }
  @media only screen and (max-width: 1024px) {
    .video {
      padding-top: 322px;
      text-align: center;
      justify-content: center; } }
  .video__in {
    width: 100%; }
    @media only screen and (max-width: 1024px) {
      .video__in {
        width: auto; } }
  .video__media {
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      .video__media {
        background-image: url("/img/video-landscape.png"); } }
    @media only screen and (max-width: 1024px) and (orientation: portrait) {
      .video__media {
        background-image: url("/img/video-portrait.png"); } }
  .video__play-btn {
    position: absolute;
    top: 50vh;
    top: calc(1 * 50);
    top: calc(var(--vh, 1) * 50);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 1px solid #ffffff;
    border-radius: 40px;
    cursor: pointer;
    height: 44px;
    width: 80px; }
    .video__play-btn:before {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 18px solid #ffffff;
      content: '';
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: opacity ease-out 0.3s;
      transition: opacity ease-out 0.3s; }
  .video__content {
    max-width: 890px;
    position: relative;
    width: 50%;
    z-index: 2; }
    @media only screen and (max-width: 1024px) {
      .video__content {
        width: 100%; } }
  .video__headline {
    line-height: 1.2;
    letter-spacing: 1px;
    margin-bottom: 0.65rem;
    font-size: 100px;
    font-size: calc(80px + (100 - 80) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
    @media (max-width: 1440px) {
      .video__headline {
        font-size: 80px; } }
    @media (min-width: 1920px) {
      .video__headline {
        font-size: 100px; } }
    @media only screen and (max-width: 1440px) {
      .video__headline {
        font-size: 80px;
        font-size: calc(60px + (80 - 60) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .video__headline {
      font-size: 60px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .video__headline {
      font-size: 80px; } }
    @media only screen and (max-width: 1024px) {
      .video__headline {
        line-height: 1.31;
        margin-bottom: 30px;
        font-size: 60px;
        font-size: calc(52px + (60 - 52) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .video__headline {
      font-size: 52px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .video__headline {
      font-size: 60px; } }
    @media only screen and (max-width: 414px) {
      .video__headline {
        font-size: 32px; } }
  .video__text {
    line-height: 1.5;
    letter-spacing: 0.4px;
    font-size: 40px;
    font-size: calc(32px + (40 - 32) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .video__text {
        font-size: 32px; } }
    @media (min-width: 1920px) {
      .video__text {
        font-size: 40px; } }
    @media only screen and (max-width: 1440px) {
      .video__text {
        font-size: 32px;
        font-size: calc(26px + (32 - 26) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .video__text {
      font-size: 26px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .video__text {
      font-size: 32px; } }
    @media only screen and (max-width: 1024px) {
      .video__text {
        font-size: 26px;
        font-size: calc(20px + (26 - 20) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .video__text {
      font-size: 20px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .video__text {
      font-size: 26px; } }
    @media only screen and (max-width: 414px) {
      .video__text {
        font-size: 16px; } }
    .video__text p:not(:last-of-type) {
      margin-bottom: 20px; }
  .video__item, .video__media {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .video__item {
    z-index: -1;
    object-fit: cover; }
  .video__curtain {
    z-index: 1;
    background-color: #000;
    position: absolute; }
    .video__curtain:nth-of-type(1), .video__curtain:nth-of-type(2) {
      height: 100px;
      left: 0;
      width: 100%; }
      @media only screen and (max-width: 1440px) {
        .video__curtain:nth-of-type(1), .video__curtain:nth-of-type(2) {
          height: 70px; } }
    .video__curtain:nth-of-type(3), .video__curtain:nth-of-type(4) {
      height: 100%;
      width: 100px; }
      @media only screen and (max-width: 1440px) {
        .video__curtain:nth-of-type(3), .video__curtain:nth-of-type(4) {
          width: 70px; } }
    .video__curtain:nth-of-type(1) {
      top: 0; }
    .video__curtain:nth-of-type(2) {
      bottom: 0; }
    .video__curtain:nth-of-type(3) {
      left: 0; }
    .video__curtain:nth-of-type(4) {
      right: 0; }
  .video__shadow {
    z-index: 1;
    background-color: rgba(2, 0, 8, 0.6);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.benefits {
  background-image: url("/img/bg.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: calc(1 * 100);
  height: calc(var(--vh, 1) * 100); }
  .benefits__row {
    display: flex;
    margin-bottom: 20vmin;
    width: 100%; }
    @media only screen and (max-width: 1024px) {
      .benefits__row {
        display: contents; } }
  .benefits__item {
    align-items: center;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 2rem 1.5rem; }
    @media only screen and (max-width: 1024px) {
      .benefits__item {
        padding: 100px 46px; } }
    @media only screen and (max-width: 1024px) and (orientation: portrait) {
      .benefits__item {
        flex-direction: column;
        padding-top: 25vmin;
        padding-bottom: 25vmin; } }
  .benefits__headline, .benefits__text {
    width: 50%; }
    @media only screen and (max-width: 1024px) {
      .benefits__headline, .benefits__text {
        width: 100%; } }
  @media only screen and (max-width: 1024px) {
    .benefits__header {
      flex-grow: 1;
      order: 1;
      width: 100%; } }
  .benefits__headline {
    font-size: 8vmin;
    line-height: 1.1;
    letter-spacing: 0.26vmin;
    position: relative;
    background-clip: text;
    text-fill: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat-Medium', Helvetica, sans-serif; }
    @media only screen and (max-width: 1440px) {
      .benefits__headline {
        font-size: 6vmin; } }
    @media only screen and (max-width: 1024px) {
      .benefits__headline {
        font-size: 6vmin;
        margin-bottom: 7.5vmin;
        padding-left: 2vmin; } }
    @media only screen and (max-width: 414px) {
      .benefits__headline {
        font-size: 8.2vmin; } }
    .benefits__headline_violet {
      background-image: linear-gradient(69deg, #f419a8 6%, #7f6fdb 40%, #00f1f9 73%); }
    .benefits__headline_pink {
      background-image: linear-gradient(70deg, #ff2dd6 10%, #f12d2d 49%, #e1f701 85%); }
    .benefits__headline_green {
      background-image: -webkit-gradient(linear, left top, right top, color-stop(6%, #ff854f), color-stop(43%, #ffeb4f), color-stop(80%, #00e300));
      background-image: linear-gradient(to right, #ff854f 6%, #ffeb4f 43%, #00e300 80%); }
    .benefits__headline_blue {
      background-image: linear-gradient(78deg, #7f6fdb 7%, #00f1f9 57%, #00f95f 99%); }
    .benefits__headline_dark-blue {
      background-image: linear-gradient(76deg, #00f1f9 7%, #7f6fdb 54%, #f419a8 102%); }
    .benefits__headline_orange {
      background-image: linear-gradient(73deg, #00e300 -2%, #ffeb4f 44%, #ff7649 92%); }
    .benefits__headline span {
      background-image: inherit;
      display: inline-block;
      max-width: 770px;
      background-clip: text;
      text-fill: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
      .benefits__headline span:nth-of-type(1), .benefits__headline span:nth-of-type(2) {
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
        -webkit-transition: visibility ease-out 0.15s, -webkit-transform ease-out 0.3s;
        transition: visibility ease-out 0.15s, -webkit-transform ease-out 0.3s;
        transition: transform ease-out 0.3s, visibility ease-out 0.15s;
        transition: transform ease-out 0.3s, visibility ease-out 0.15s, -webkit-transform ease-out 0.3s;
        visibility: hidden;
        min-width: 100%;
        font-family: 'Montserrat-Hairline', Helvetica, sans-serif; }
      .benefits__headline span:nth-of-type(1) {
        opacity: 0.1; }
      .benefits__headline span:nth-of-type(2) {
        opacity: 0.3; }
    .is-animated .benefits__headline span {
      visibility: visible; }
      .is-animated .benefits__headline span:nth-of-type(1) {
        -webkit-transform: translate(-0.8rem, -0.7rem);
                transform: translate(-0.8rem, -0.7rem); }
        @media only screen and (max-width: 1024px) {
          .is-animated .benefits__headline span:nth-of-type(1) {
            -webkit-transform: translate(-16px, -18px);
                    transform: translate(-16px, -18px); } }
      .is-animated .benefits__headline span:nth-of-type(2) {
        -webkit-transform: translate(-0.4rem, -0.5rem);
                transform: translate(-0.4rem, -0.5rem); }
        @media only screen and (max-width: 1024px) {
          .is-animated .benefits__headline span:nth-of-type(2) {
            -webkit-transform: translate(-10px, -12px);
                    transform: translate(-10px, -12px); } }
  .benefits__text {
    line-height: 1.5;
    margin-left: 15vmin;
    max-width: 730px;
    font-size: 30px;
    font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-Regular', Helvetica, sans-serif; }
    @media (max-width: 1440px) {
      .benefits__text {
        font-size: 24px; } }
    @media (min-width: 1920px) {
      .benefits__text {
        font-size: 30px; } }
    @media only screen and (max-width: 1440px) {
      .benefits__text {
        font-size: 24px;
        font-size: calc(20px + (24 - 20) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .benefits__text {
      font-size: 20px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .benefits__text {
      font-size: 24px; } }
    @media only screen and (max-width: 1024px) {
      .benefits__text {
        font-size: 2.8vmin;
        order: 3;
        margin-left: 0;
        max-width: 100%; } }
    @media only screen and (max-width: 414px) {
      .benefits__text {
        font-size: 3.6vmin; } }
  .benefits__icons {
    width: 100%; }
    @media only screen and (max-width: 1024px) {
      .benefits__icons {
        margin-bottom: 38px;
        order: 2; } }
  .benefits__icon {
    display: inline-block;
    height: 0.8rem; }
    .benefits__icon img {
      height: 100%;
      display: block; }
    @media only screen and (max-width: 414px) {
      .benefits__icon {
        height: 20px; } }
    .benefits__icon:not(:last-of-type) {
      margin-right: 0.6rem; }
  .benefits__video,
  .benefits__video video {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    @media only screen and (max-width: 1024px) {
      .benefits__video,
      .benefits__video video {
        display: none; } }
  .benefits__video video {
    object-fit: cover; }
  .benefits__video:after {
    background-color: rgba(2, 0, 8, 0.2);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .benefits__promo {
    display: none; }
    @media only screen and (max-width: 1024px) {
      .benefits__promo {
        display: block; } }
  .benefits__played-video {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }
    .benefits__played-video video {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 100%; }
  .benefits__play-btn {
    border: 1px solid #ffffff;
    border-radius: 40px;
    cursor: pointer;
    height: 44px;
    position: relative;
    width: 80px; }
    .benefits__play-btn.is-active:before {
      opacity: 0; }
    .benefits__play-btn.is-active:after {
      opacity: 1; }
    .benefits__play-btn:before {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 18px solid #ffffff;
      content: '';
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: opacity ease-out 0.3s;
      transition: opacity ease-out 0.3s; }
    .benefits__play-btn:after {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("/img/icons/pause.svg");
      content: '';
      height: 40px;
      left: 50%;
      position: absolute;
      top: 50%;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: opacity ease-out 0.3s;
      transition: opacity ease-out 0.3s;
      width: 40px; }

.roadmap {
  display: flex;
  min-height: auto;
  padding-top: 150px;
  padding: 2rem 1.5rem; }
  @media only screen and (max-width: 1024px) {
    .roadmap {
      padding: 100px 46px; } }
  @media only screen and (max-width: 1024px) {
    .roadmap {
      flex-direction: column; } }
  .roadmap__side {
    width: 50%; }
    @media only screen and (max-width: 1024px) {
      .roadmap__side {
        width: 100%; } }
  .roadmap__headline {
    line-height: 0.8;
    padding-right: 2rem;
    max-width: 45vw;
    font-size: 100px;
    font-size: calc(80px + (100 - 80) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .roadmap__headline {
        font-size: 80px; } }
    @media (min-width: 1920px) {
      .roadmap__headline {
        font-size: 100px; } }
    @media only screen and (max-width: 1440px) {
      .roadmap__headline {
        font-size: 80px;
        font-size: calc(60px + (80 - 60) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .roadmap__headline {
      font-size: 60px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .roadmap__headline {
      font-size: 80px; } }
    @media only screen and (max-width: 1024px) {
      .roadmap__headline {
        font-size: 52px;
        margin-bottom: 50px;
        max-width: auto;
        padding-left: 2.7vmin; } }
    @media only screen and (max-width: 414px) {
      .roadmap__headline {
        font-size: 36px; } }
  .roadmap__steps {
    position: relative; }
    .roadmap__steps:before,
    .roadmap__steps .roadmap__progress {
      content: '';
      left: 0;
      position: absolute;
      -webkit-transform: translateX(-0.6rem);
              transform: translateX(-0.6rem);
      width: 0.6rem; }
      @media only screen and (max-width: 1024px) {
        .roadmap__steps:before,
        .roadmap__steps .roadmap__progress {
          width: 30px; } }
    .roadmap__steps:before {
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(70%, #ece1ff), to(rgba(255, 255, 255, 0.21)));
      background-image: linear-gradient(to bottom, #ece1ff 70%, rgba(255, 255, 255, 0.21) 100%);
      height: calc(100% - 1.5rem);
      opacity: 0.08;
      top: 0.7rem; }
      @media only screen and (max-width: 1024px) {
        .roadmap__steps:before {
          top: 35px; } }
  .roadmap__progress {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 8, 13, 0)), color-stop(28%, #f419a8), color-stop(84%, #806df4), to(#00f1f9));
    background-image: linear-gradient(to bottom, rgba(10, 8, 13, 0) 0%, #f419a8 28%, #806df4 84%, #00f1f9 100%); }
  .roadmap__item {
    padding-left: 1rem;
    position: relative; }
    @media only screen and (max-width: 1024px) {
      .roadmap__item {
        padding-left: 23px; } }
    .roadmap__item:not(:last-of-type) {
      margin-bottom: 1.5rem; }
      @media only screen and (max-width: 1024px) {
        .roadmap__item:not(:last-of-type) {
          margin-bottom: 70px; } }
    .roadmap__item:last-of-type .roadmap__title {
      background-image: linear-gradient(87deg, #ff2dd6 -12%, #ff120c 89%);
      background-clip: text;
      text-fill: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    @media only screen and (max-width: 1024px) {
      .roadmap__item:last-of-type .roadmap__text {
        font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; } }
  .roadmap__title {
    display: inline-block;
    line-height: 1.5;
    letter-spacing: 1.52px;
    position: relative;
    font-size: 48px;
    font-size: calc(38px + (48 - 38) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
    @media (max-width: 1440px) {
      .roadmap__title {
        font-size: 38px; } }
    @media (min-width: 1920px) {
      .roadmap__title {
        font-size: 48px; } }
    @media only screen and (max-width: 1440px) {
      .roadmap__title {
        font-size: 38px;
        font-size: calc(24px + (38 - 24) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .roadmap__title {
      font-size: 24px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .roadmap__title {
      font-size: 38px; } }
    .roadmap__title:before {
      background-color: #fff;
      bottom: 0;
      content: '';
      height: 2px;
      left: -1.75rem;
      position: absolute;
      width: calc(100% + 2.55rem); }
      @media only screen and (max-width: 1024px) {
        .roadmap__title:before {
          left: -62px;
          width: calc(100% + 85px); } }
  .roadmap__text {
    line-height: 1.5;
    letter-spacing: 0.95px;
    margin-top: 0.48rem;
    max-width: 705px;
    position: relative;
    font-size: 30px;
    font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .roadmap__text {
        font-size: 24px; } }
    @media (min-width: 1920px) {
      .roadmap__text {
        font-size: 30px; } }
    @media only screen and (max-width: 1440px) {
      .roadmap__text {
        font-size: 24px;
        font-size: calc(18px + (24 - 18) * ((100vw - 1440px) / (1920 - 1440))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1440px) {
    .roadmap__text {
      font-size: 18px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1920px) {
    .roadmap__text {
      font-size: 24px; } }
    @media only screen and (max-width: 1024px) {
      .roadmap__text {
        line-height: 1.63;
        letter-spacing: 0.51px;
        margin-top: 25px;
        font-size: 18px;
        font-size: calc(16px + (18 - 16) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .roadmap__text {
      font-size: 16px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .roadmap__text {
      font-size: 18px; } }
  .roadmap__icon {
    display: block;
    left: -1.8rem;
    position: absolute;
    top: 0;
    width: 1rem; }
    @media only screen and (max-width: 1024px) {
      .roadmap__icon {
        left: -58px;
        width: 40px; } }

.slider {
  position: relative; }
  .slider__item {
    display: block;
    color: #fff;
    height: 100%;
    padding: 1rem;
    position: relative;
    margin: auto;
    max-width: calc(100% - 0.3rem);
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .slider__item:before {
      pointer-events: none;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.45)), linear-gradient(64deg, #0a050d -7%, #120e17 104%);
      opacity: 0.05; }
    .slider__item img {
      display: block;
      margin: auto;
      max-height: 1.5rem;
      max-width: 3.8rem;
      width: 100%; }
  .slider__content {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .slider__image {
    margin-top: -30%;
    padding-top: 48%;
    margin-bottom: 40px;
    width: 100%;
    position: relative; }
    @media only screen and (max-width: 1024px) {
      .slider__image {
        margin-top: -100px; } }
    @media only screen and (max-width: 414px) {
      .slider__image {
        margin-top: -70px; } }
    .slider__image img {
      max-height: none;
      max-width: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .slider__text {
    flex-grow: 1;
    line-height: 1.67;
    letter-spacing: 0.95px;
    margin-bottom: 0.8rem;
    text-align: center;
    font-size: 30px;
    font-size: calc(21px + (30 - 21) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-Regular', Helvetica, sans-serif; }
    @media (max-width: 1440px) {
      .slider__text {
        font-size: 21px; } }
    @media (min-width: 1920px) {
      .slider__text {
        font-size: 30px; } }
    @media only screen and (max-width: 1440px) {
      .slider__text {
        font-size: 21px;
        font-size: calc(16px + (21 - 16) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .slider__text {
      font-size: 16px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .slider__text {
      font-size: 21px; } }
  .slider__more {
    display: flex;
    align-items: center;
    font-family: 'Montserrat-Regular', Helvetica, sans-serif;
    color: #21c4ff;
    font-size: 20px;
    line-height: 2.37;
    letter-spacing: 0.05em; }
    .slider__more svg {
      display: block;
      margin-left: 4px;
      height: 30px;
      width: 14px;
      fill: #21c4ff; }
    @media only screen and (max-width: 414px) {
      .slider__more {
        font-size: 16px; } }
  .slider__btn {
    cursor: pointer;
    height: 100%;
    position: absolute;
    top: 0;
    width: 2rem; }
    .slider__btn_prev {
      left: 0.15rem;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
      @media only screen and (max-width: 1024px) {
        .slider__btn_prev {
          left: 35px; } }
    .slider__btn_next {
      right: 0.45rem;
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }
      @media only screen and (max-width: 1024px) {
        .slider__btn_next {
          right: 45px; } }
  .slider .slick-track {
    align-items: stretch;
    display: flex; }
  .slider .slick-list {
    overflow: visible; }
  .slider .slick-slide {
    align-items: stretch;
    display: flex;
    height: auto; }
    .slider .slick-slide div {
      width: 100%; }
  .slider_news {
    margin: 0 -55px; }
    .slider_news .slider__text {
      text-align: left;
      margin-bottom: 10px; }
    .slider_news .slider__item {
      padding: 0.9rem; }

.reviews {
  min-height: auto;
  overflow: hidden;
  padding: 2rem 1.5rem; }
  @media only screen and (max-width: 1024px) {
    .reviews {
      padding: 100px 46px; } }
  @media only screen and (max-width: 1024px) {
    .reviews {
      padding: 50px 0; } }

.join-us {
  min-height: auto;
  padding: 2rem 1.5rem; }
  @media only screen and (max-width: 1024px) {
    .join-us {
      padding: 100px 46px; } }
  @media only screen and (max-width: 1024px) {
    .join-us {
      display: none; } }
  .join-us__headline {
    line-height: 0.6;
    margin-bottom: 1.38rem;
    font-size: 100px;
    font-size: calc(80px + (100 - 80) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .join-us__headline {
        font-size: 80px; } }
    @media (min-width: 1920px) {
      .join-us__headline {
        font-size: 100px; } }
    @media only screen and (max-width: 1440px) {
      .join-us__headline {
        font-size: 80px;
        font-size: calc(60px + (80 - 60) * ((100vw - 1440px) / (1920 - 1440))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1440px) {
    .join-us__headline {
      font-size: 60px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1920px) {
    .join-us__headline {
      font-size: 80px; } }
  .join-us__row {
    align-items: stretch;
    display: flex; }
  .join-us__col {
    width: 100%; }
    .join-us__col:not(:last-of-type) {
      margin-right: 0.6rem; }
  .join-us__title {
    letter-spacing: 0.25px;
    margin-bottom: 0.42rem;
    font-size: 30px;
    font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-Light', Helvetica, sans-serif; }
    @media (max-width: 1440px) {
      .join-us__title {
        font-size: 24px; } }
    @media (min-width: 1920px) {
      .join-us__title {
        font-size: 30px; } }
    @media only screen and (max-width: 1440px) {
      .join-us__title {
        font-size: 24px;
        font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    .join-us__title {
      font-size: 18px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .join-us__title {
      font-size: 24px; } }
  .join-us__socials {
    -webkit-transform: translateY(25%);
            transform: translateY(25%); }

.faq-list {
  position: relative; }
  .faq-list__item {
    padding: 0 60px 17px;
    border-bottom: 1px solid rgba(54, 51, 58, 0.8); }
    @media only screen and (max-width: 1024px) {
      .faq-list__item {
        padding: 0; } }
  .faq-list__question {
    padding: 40px 0 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat-Regular', Helvetica, sans-serif;
    font-size: 30px;
    line-height: 1.6;
    font-weight: normal;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent; }
    @media only screen and (max-width: 1024px) {
      .faq-list__question {
        font-size: 18px;
        padding: 16px 0; } }
    .faq-list__question svg {
      display: block;
      flex-shrink: 0;
      width: 24px;
      margin-left: 30px;
      -webkit-transform: rotate(0);
              transform: rotate(0);
      -webkit-transition: -webkit-transform .2s ease;
      transition: -webkit-transform .2s ease;
      transition: transform .2s ease;
      transition: transform .2s ease, -webkit-transform .2s ease;
      fill: #21c4ff; }
      @media only screen and (max-width: 1024px) {
        .faq-list__question svg {
          width: 14px;
          margin-left: 20px; } }
    .faq-list__question.is-expanded {
      font-family: 'Montserrat-SemiBold', Helvetica, sans-serif;
      font-weight: bold; }
      .faq-list__question.is-expanded svg {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
  .faq-list__answer-wrap {
    max-width: 1220px; }
  .faq-list__answer-text {
    padding-bottom: 30px;
    font-family: 'Montserrat-Regular', Helvetica, sans-serif;
    font-size: 24px;
    color: #a1a3b2;
    line-height: 1.6;
    font-weight: normal; }
    @media only screen and (max-width: 1024px) {
      .faq-list__answer-text {
        font-size: 14px;
        padding-bottom: 14px; } }
  .faq-list__more {
    display: inline-flex;
    align-items: center;
    background: -webkit-gradient(linear, left top, left bottom, from(#21c4ff), to(#21c4ff)), -webkit-gradient(linear, left top, right top, color-stop(-8%, #7f6fdb), color-stop(36%, #00f1f9));
    background: linear-gradient(to bottom, #21c4ff, #21c4ff), linear-gradient(to right, #7f6fdb -8%, #00f1f9 36%);
    position: relative;
    font-size: 20px;
    line-height: 1.48;
    letter-spacing: 1.52px;
    margin-bottom: 20px;
    background-clip: text;
    text-fill: transparent;
    cursor: pointer;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
    @media only screen and (max-width: 1024px) {
      .faq-list__more {
        font-size: 14px; } }
    .faq-list__more:hover svg {
      -webkit-transform: translateX(4px);
              transform: translateX(4px); }
    .faq-list__more svg {
      display: block;
      background: inherit;
      width: 10px;
      margin-left: 14px;
      fill: #21c4ff;
      -webkit-transition: -webkit-transform .15s ease;
      transition: -webkit-transform .15s ease;
      transition: transform .15s ease;
      transition: transform .15s ease, -webkit-transform .15s ease;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
      @media only screen and (max-width: 1024px) {
        .faq-list__more svg {
          margin-left: 10px; } }

.faq-answer {
  position: relative;
  width: 100%;
  padding: 45px 60px 28px;
  border-bottom: 1px solid rgba(54, 51, 58, 0.8); }
  @media only screen and (max-width: 1024px) {
    .faq-answer {
      padding: 20px 16px 9px; } }
  .faq-answer__text {
    max-width: 1170px;
    padding-bottom: 10px;
    padding-top: 6px;
    font-family: 'Montserrat-Regular', Helvetica, sans-serif;
    font-size: 24px;
    color: #a1a3b2;
    line-height: 1.6;
    font-weight: normal; }
    @media only screen and (max-width: 1024px) {
      .faq-answer__text {
        font-size: 18px; } }
  .faq-answer__title {
    position: relative;
    padding-right: 35px;
    padding-bottom: 10px;
    cursor: pointer; }
  .faq-answer__toggle {
    height: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: height .4s ease, opacity .3s ease;
    transition: height .4s ease, opacity .3s ease; }
  .faq-answer__icon {
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 60px; }
    @media only screen and (max-width: 1024px) {
      .faq-answer__icon {
        top: 16px;
        right: 16px; } }
  .faq-answer__full-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: 20px;
    line-height: 1.48;
    letter-spacing: 1.52px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #21c4ff;
    cursor: pointer; }
    @media only screen and (max-width: 1024px) {
      .faq-answer__full-link {
        font-size: 14px; } }
    .faq-answer__full-link svg {
      display: block;
      background: inherit;
      width: 10px;
      margin-left: 14px;
      fill: #21c4ff;
      -webkit-transition: -webkit-transform .15s ease;
      transition: -webkit-transform .15s ease;
      transition: transform .15s ease;
      transition: transform .15s ease, -webkit-transform .15s ease;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
      @media only screen and (max-width: 1024px) {
        .faq-answer__full-link svg {
          margin-left: 10px; } }
    .faq-answer__full-link:hover svg {
      -webkit-transform: translateX(4px);
              transform: translateX(4px); }
  .faq-answer__icon svg {
    display: block;
    flex-shrink: 0;
    width: 24px;
    margin-left: 30px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
    fill: #21c4ff;
    -webkit-transform-origin: center;
            transform-origin: center; }
    @media only screen and (max-width: 1024px) {
      .faq-answer__icon svg {
        width: 12px;
        margin-left: 20px; } }
  .faq-answer.is-active .faq-answer__title {
    font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
  .faq-answer.is-active .faq-answer__icon svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.tabs {
  position: relative;
  width: 100%;
  -webkit-transition: opacity ease-in 0.4s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, transform 0.6s ease-in;
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, transform 0.6s ease-in, -webkit-transform 0.6s ease-in; }
  .tabs__header {
    position: relative;
    border-bottom: 2px solid #5b5662;
    padding: 0 60px;
    margin-bottom: 60px; }
    @media only screen and (max-width: 1024px) {
      .tabs__header {
        padding: 0;
        margin-bottom: 30px; } }
  .tabs__list {
    display: flex;
    padding-left: 0;
    list-style: none;
    margin: 0; }
    @media only screen and (max-width: 1024px) {
      .tabs__list {
        display: block;
        padding: 0 16px;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: -1px;
        white-space: nowrap; } }
    @media only screen and (max-width: 1024px) {
      .tabs__list:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 11px;
        height: 100%;
        width: 100px;
        box-shadow: inset -140px 0 140px -140px #0f0b15;
        pointer-events: none; } }
  .tabs__item {
    position: relative;
    padding-bottom: 18px;
    color: #ffffff;
    font-family: 'Montserrat-Bold', Helvetica, sans-serif;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin-right: 60px;
    flex-shrink: 0;
    font-size: 30px;
    -webkit-tap-highlight-color: transparent; }
    @media only screen and (max-width: 1024px) {
      .tabs__item {
        display: inline-block;
        font-size: 18px;
        margin-right: 0; } }
    @media only screen and (max-width: 1024px) {
      .tabs__item:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -2px;
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
        width: 100%;
        height: 4px;
        background-image: -webkit-gradient(linear, left top, right top, color-stop(-15%, #f419a8), color-stop(43%, #7f6fdb), to(#00f1f9));
        background-image: linear-gradient(to right, #f419a8 -15%, #7f6fdb 43%, #00f1f9);
        -webkit-transform-origin: center;
                transform-origin: center;
        -webkit-transition: -webkit-transform .2s ease;
        transition: -webkit-transform .2s ease;
        transition: transform .2s ease;
        transition: transform .2s ease, -webkit-transform .2s ease; } }
    @media only screen and (max-width: 1024px) {
      .tabs__item:not(:last-of-type) {
        margin-right: 40px; } }
    .tabs__item.is-active:before {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
  .tabs__answer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat-Regular', Helvetica, sans-serif;
    font-size: 30px;
    line-height: 1.6;
    font-weight: normal;
    -webkit-tap-highlight-color: transparent; }
    @media only screen and (max-width: 1024px) {
      .tabs__answer {
        font-size: 18px; } }
  .tabs.enter-active {
    opacity: 0;
    -webkit-transition: opacity ease-in 0s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, transform 0.6s ease-in;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, transform 0.6s ease-in, -webkit-transform 0.6s ease-in; }
  .tabs.enter-done {
    opacity: 1; }
  .tabs.enter-active, .tabs.enter-done {
    visibility: visible;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .tabs.exit-active {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .tabs__active-line {
    position: absolute;
    bottom: -2px;
    height: 4px;
    will-change: left, width;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(-15%, #f419a8), color-stop(43%, #7f6fdb), to(#00f1f9));
    background-image: linear-gradient(to right, #f419a8 -15%, #7f6fdb 43%, #00f1f9);
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
    @media only screen and (max-width: 1024px) {
      .tabs__active-line {
        display: none; } }

.details-content {
  max-width: 1020px;
  width: 100%;
  color: #a1a3b2;
  font-size: 3vmin;
  line-height: 1.47; }
  @media only screen and (max-width: 1024px) {
    .details-content {
      font-size: 26px;
      font-size: calc(16px + (26 - 16) * ((100vw - 414px) / (1024 - 414)));
      line-height: 1.7; } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .details-content {
      font-size: 16px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .details-content {
      font-size: 26px; } }
  .details-content p + p {
    margin-top: 4vmin; }
    @media only screen and (max-width: 1024px) {
      .details-content p + p {
        margin: 0; } }
  .details-content ul {
    margin: 4vmin 0;
    padding-left: 4vmin; }
  .details-content ul + p,
  .details-content ol + p {
    margin-top: 14vmin; }
  .details-content li {
    list-style: disc; }
  .details-content li + li {
    margin-top: 4vmin; }
  .details-content a {
    color: #ffffff; }
  .details-content strong, .details-content b {
    font-family: 'Montserrat-Bold', Helvetica, sans-serif;
    color: #fff; }
  .details-content img {
    max-width: 100%;
    height: auto;
    margin: 5vmin 0; }
  .details-content .video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin: 5vmin 0; }
    .details-content .video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.details-header {
  display: flex;
  align-items: center;
  margin: 0 0 15vmin; }
  @media only screen and (max-width: 1024px) {
    .details-header {
      display: block;
      margin-bottom: 50px; } }
  .details-header.is-column {
    display: block; }
    .details-header.is-column .details-header__back {
      margin-bottom: 4vmin; }
  .details-header svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 7vmin;
    margin-right: 7vmin; }
  .details-header__title {
    font-size: 10vmin;
    color: #ffffff;
    font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
    @media only screen and (max-width: 1024px) {
      .details-header__title {
        max-width: none;
        font-size: 30px;
        line-height: 1.5; } }
  .details-header__back {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1024px) {
      .details-header__back {
        margin-bottom: 30px; } }
    .details-header__back svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      width: 7vmin;
      margin-right: 7vmin; }
      @media only screen and (max-width: 1024px) {
        .details-header__back svg {
          margin-right: 40px;
          width: 27px; } }

.details-sidebar__title {
  padding-left: 20px;
  font-size: 3vmin;
  line-height: 1.47;
  font-family: 'Montserrat-Bold', Helvetica, sans-serif;
  margin-bottom: 8vmin; }

.details-sidebar__links {
  border-left: 1px solid #ffffff; }

.details-sidebar__link {
  position: relative;
  display: block;
  padding: 20px;
  text-decoration: none;
  color: #a1a3b2;
  font-size: 3vmin;
  line-height: 1.47; }
  @media only screen and (max-width: 1440px) {
    .details-sidebar__link {
      font-size: 18px; } }
  .details-sidebar__link:focus {
    outline: none; }
  .details-sidebar__link:before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 3px;
    height: 100%;
    background-color: #21C4FF;
    -webkit-transition: -webkit-transform .3s ease-in;
    transition: -webkit-transform .3s ease-in;
    transition: transform .3s ease-in;
    transition: transform .3s ease-in, -webkit-transform .3s ease-in;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0); }
  .details-sidebar__link.is-active {
    font-family: 'Montserrat-Bold', Helvetica, sans-serif;
    color: #ffffff; }
    .details-sidebar__link.is-active:before {
      -webkit-transform: scaleY(1);
              transform: scaleY(1); }

.faq-details {
  position: relative;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: opacity ease-in 0.9s, -webkit-transform ease-in 0.5s;
  transition: opacity ease-in 0.9s, -webkit-transform ease-in 0.5s;
  transition: transform ease-in 0.5s, opacity ease-in 0.9s;
  transition: transform ease-in 0.5s, opacity ease-in 0.9s, -webkit-transform ease-in 0.5s;
  opacity: 0; }
  @media only screen and (max-width: 1024px) {
    .faq-details {
      padding: 0 16px; } }
  .faq-details__in {
    display: flex;
    align-items: flex-start;
    width: 100%; }
  .faq-details__body {
    flex-grow: 1; }
  .faq-details__sidebar {
    margin-left: 110px;
    width: 100%;
    max-width: 450px;
    flex-shrink: 0; }
    @media only screen and (max-width: 1440px) {
      .faq-details__sidebar {
        max-width: 360px;
        margin-left: 80px; } }
    @media only screen and (max-width: 1024px) {
      .faq-details__sidebar {
        display: none; } }
  .faq-details.enter-active, .faq-details.enter-done {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  .faq-details.enter, .faq-details.exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    opacity: 0; }
  .faq-details.exit-active {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: opacity ease-in 0s, -webkit-transform ease-in 0.5s;
    transition: opacity ease-in 0s, -webkit-transform ease-in 0.5s;
    transition: transform ease-in 0.5s, opacity ease-in 0s;
    transition: transform ease-in 0.5s, opacity ease-in 0s, -webkit-transform ease-in 0.5s; }

.faq {
  background-image: linear-gradient(213deg, #000000 0%, #140e1c 23%, #000000 75%);
  position: relative;
  min-height: 100vh; }
  @media only screen and (max-width: 1024px) {
    .faq {
      min-height: auto; } }
  .faq__in {
    padding: 2rem 1.5rem; }
    @media only screen and (max-width: 1024px) {
      .faq__in {
        padding: 100px 46px; } }
    @media only screen and (max-width: 1024px) {
      .faq__in {
        padding: 130px 0 90px; } }

.vacancy {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#29232F), to(#19151F));
  background-image: linear-gradient(to bottom, #29232F 0%, #19151F 100%);
  position: relative;
  padding: 45px 40px;
  height: 100%;
  min-height: 200px; }
  @media only screen and (max-width: 1440px) {
    .vacancy {
      min-height: 170px; } }
  @media only screen and (max-width: 1024px) {
    .vacancy {
      min-height: 120px; } }
  @media only screen and (max-width: 768px) {
    .vacancy {
      display: flex;
      align-items: center;
      min-height: 80px;
      padding: 15px 30px; } }
  .vacancy:hover:before {
    opacity: 1; }
  .vacancy:hover .vacancy__icon {
    opacity: 1; }
  .vacancy:hover .vacancy__title {
    color: #000000; }
  .vacancy:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(71deg, #00f1f9 -3%, #00f93a 99%);
    opacity: 0;
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in; }
  .vacancy__in {
    position: relative;
    z-index: 2; }
  .vacancy__icon {
    position: absolute;
    z-index: 2;
    right: 35px;
    bottom: 35px;
    width: 24px;
    opacity: 0;
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
    color: #000000; }
    @media only screen and (max-width: 1440px) {
      .vacancy__icon {
        bottom: 24px;
        right: 24px; } }
    @media only screen and (max-width: 768px) {
      .vacancy__icon {
        display: none; } }
  .vacancy__title {
    font-family: 'Montserrat-Bold', Helvetica, sans-serif;
    font-size: 1.6vw;
    line-height: 1.67;
    color: #ffffff;
    -webkit-transition: color .2s ease-in;
    transition: color .2s ease-in; }
    @media only screen and (max-width: 1440px) {
      .vacancy__title {
        font-size: 22px; } }
    @media only screen and (max-width: 1024px) {
      .vacancy__title {
        font-size: 18px; } }

.filter {
  -webkit-transition: opacity ease-in 0.4s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, transform 0.6s ease-in;
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, transform 0.6s ease-in, -webkit-transform 0.6s ease-in; }
  @media only screen and (max-width: 1024px) {
    .filter {
      padding: 0 16px; } }
  .filter__title {
    font-size: 10vmin;
    margin-bottom: 10vmin;
    color: #ffffff;
    font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
    @media only screen and (max-width: 1024px) {
      .filter__title {
        font-size: 30px;
        line-height: 1.5; } }
  .filter__content {
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 768px) {
      .filter__content {
        display: block; } }
  .filter__sidebar {
    width: 21vw;
    flex-shrink: 0;
    margin-right: 6.6vw; }
    @media only screen and (max-width: 1024px) {
      .filter__sidebar {
        width: 30vw; } }
  @media only screen and (max-width: 1024px) and (max-width: 768px) {
    .filter__sidebar {
      position: relative;
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
      border-bottom: 2px solid rgba(54, 51, 58, 0.8); } }
  .filter__body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1090px; }
    @media only screen and (max-width: 768px) {
      .filter__body {
        margin-top: 40px; } }
  @media only screen and (max-width: 768px) {
    .filter__cities {
      z-index: 3;
      width: 100%;
      height: 0;
      overflow: hidden;
      position: absolute;
      top: 100%;
      margin-top: 2px;
      left: 0;
      padding: 0 16px;
      background-image: linear-gradient(213deg, #000000 0%, #140e1c 23%, #000000 75%); }
      .filter__cities.is-opened {
        height: auto;
        padding: 16px; } }
  .filter__vacancy {
    max-width: 520px;
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 60px;
    cursor: pointer; }
    @media only screen and (max-width: 1024px) {
      .filter__vacancy {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-left: auto; } }
    @media only screen and (max-width: 768px) {
      .filter__vacancy {
        margin-bottom: 10px;
        max-width: none; } }
  .filter__selected {
    display: none; }
    @media only screen and (max-width: 768px) {
      .filter__selected {
        font-family: 'Montserrat-Bold', Helvetica, sans-serif;
        font-size: 18px;
        padding: 0 16px 6px;
        display: flex;
        align-items: center;
        justify-content: space-between; } }
    @media only screen and (max-width: 768px) {
      .filter__selected svg {
        width: 18px;
        fill: #21c4ff;
        -webkit-transition: -webkit-transform .2s ease-in;
        transition: -webkit-transform .2s ease-in;
        transition: transform .2s ease-in;
        transition: transform .2s ease-in, -webkit-transform .2s ease-in; } }
    @media only screen and (max-width: 414px) {
      .filter__selected svg {
        width: 14px; } }
    .filter__selected.is-opened svg {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
  .filter__item {
    cursor: pointer;
    padding: 30px 0;
    font-size: 1.6vw;
    font-family: 'Montserrat-Regular', Helvetica, sans-serif; }
    @media only screen and (max-width: 1440px) {
      .filter__item {
        font-size: 22px; } }
    @media only screen and (max-width: 1024px) {
      .filter__item {
        padding: 10px 0;
        line-height: 1.5;
        font-size: 18px; } }
    .filter__item.is-active {
      font-family: 'Montserrat-Bold', Helvetica, sans-serif; }
  .filter.enter-active {
    opacity: 0;
    -webkit-transition: opacity ease-in 0s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, -webkit-transform 0.6s ease-in;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, transform 0.6s ease-in;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, transform 0.6s ease-in, -webkit-transform 0.6s ease-in; }
  .filter.enter-done {
    opacity: 1; }
  .filter.enter-active, .filter.enter-done {
    visibility: visible;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .filter.exit-active {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }

.vacancies-details {
  position: relative;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: opacity ease-in 0.9s, -webkit-transform ease-in 0.5s;
  transition: opacity ease-in 0.9s, -webkit-transform ease-in 0.5s;
  transition: transform ease-in 0.5s, opacity ease-in 0.9s;
  transition: transform ease-in 0.5s, opacity ease-in 0.9s, -webkit-transform ease-in 0.5s;
  opacity: 0; }
  @media only screen and (max-width: 1024px) {
    .vacancies-details {
      padding: 0 16px; } }
  .vacancies-details__in {
    display: flex;
    align-items: flex-start;
    width: 100%; }
  .vacancies-details__body {
    flex-grow: 1; }
  .vacancies-details__btn {
    margin-top: 14vmin; }
    .vacancies-details__btn .btn {
      padding: 0.25rem 0.6rem; }
      @media only screen and (max-width: 414px) {
        .vacancies-details__btn .btn {
          width: 100%;
          padding: 0.25rem 0.15rem; } }
  .vacancies-details__sidebar {
    margin-left: 110px;
    width: 100%;
    max-width: 450px;
    flex-shrink: 0;
    padding-top: 12vmin; }
    @media only screen and (max-width: 1440px) {
      .vacancies-details__sidebar {
        max-width: 360px;
        margin-left: 80px; } }
    @media only screen and (max-width: 1024px) {
      .vacancies-details__sidebar {
        display: none; } }
  .vacancies-details.enter-active, .vacancies-details.enter-done {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  .vacancies-details.enter, .vacancies-details.exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    opacity: 0; }
  .vacancies-details.exit-active {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: opacity ease-in 0s, -webkit-transform ease-in 0.5s;
    transition: opacity ease-in 0s, -webkit-transform ease-in 0.5s;
    transition: transform ease-in 0.5s, opacity ease-in 0s;
    transition: transform ease-in 0.5s, opacity ease-in 0s, -webkit-transform ease-in 0.5s; }

.vacancies {
  background-image: linear-gradient(213deg, #000000 0%, #140e1c 23%, #000000 75%);
  position: relative;
  min-height: 100vh;
  padding: 2rem 1.5rem; }
  @media only screen and (max-width: 1024px) {
    .vacancies {
      padding: 100px 46px; } }
  @media only screen and (max-width: 1024px) {
    .vacancies {
      min-height: auto;
      padding: 130px 0 90px; } }
  .vacancies__in {
    position: relative; }

.about-item {
  display: flex; }
  .about-item + .about-item {
    margin-top: 1rem; }
  @media only screen and (max-width: 768px) {
    .about-item {
      display: block; } }
  .about-item__title {
    width: 47%;
    padding-right: 1rem;
    font-size: 46px;
    font-size: calc(42px + (46 - 42) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif;
    line-height: 1.39;
    background-clip: text;
    background-image: -webkit-gradient(linear, left top, right top, from(#ffeb4f), color-stop(93%, #00e300));
    background-image: linear-gradient(to right, #ffeb4f, #00e300 93%);
    text-fill: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
    @media (max-width: 1440px) {
      .about-item__title {
        font-size: 42px; } }
    @media (min-width: 1920px) {
      .about-item__title {
        font-size: 46px; } }
    @media only screen and (max-width: 1024px) {
      .about-item__title {
        font-size: 40px;
        font-size: calc(24px + (40 - 24) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .about-item__title {
      font-size: 24px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-item__title {
      font-size: 40px; } }
    @media only screen and (max-width: 768px) {
      .about-item__title {
        width: auto;
        padding-right: 0;
        margin-bottom: 20px; } }
  .about-item__text {
    font-family: 'Montserrat-Light', Helvetica, sans-serif;
    width: 53%;
    font-size: 30px;
    font-size: calc(26px + (30 - 26) * ((100vw - 1440px) / (1920 - 1440)));
    line-height: 1.47;
    letter-spacing: 1.27px; }
    @media (max-width: 1440px) {
      .about-item__text {
        font-size: 26px; } }
    @media (min-width: 1920px) {
      .about-item__text {
        font-size: 30px; } }
    .about-item__text p + p {
      margin-top: 0.5rem; }
    @media only screen and (max-width: 1024px) {
      .about-item__text {
        font-size: 24px;
        font-size: calc(20px + (24 - 20) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .about-item__text {
      font-size: 20px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-item__text {
      font-size: 24px; } }
    @media only screen and (max-width: 768px) {
      .about-item__text {
        width: auto; } }
  .about-item_blue .about-item__title {
    background-image: -webkit-gradient(linear, left top, right top, from(#7f6fdb), color-stop(94%, #00f1f9));
    background-image: linear-gradient(to right, #7f6fdb, #00f1f9 94%); }
  .about-item_red .about-item__title {
    background-image: linear-gradient(72deg, #ff2dd6 -9%, #f12d2d 100%); }

.about-story {
  padding: 1rem 1.5rem; }
  @media only screen and (max-width: 1600px) {
    .about-story {
      padding: 1rem 1rem; } }
  @media only screen and (max-width: 1440px) {
    .about-story {
      padding: 1rem 0.7rem; } }
  @media only screen and (max-width: 1024px) {
    .about-story {
      padding: 50px 46px; } }
  @media only screen and (max-width: 414px) {
    .about-story {
      padding: 30px 16px; } }
  .about-story__title {
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif;
    font-size: 48px;
    font-size: calc(40px + (48 - 40) * ((100vw - 1440px) / (1920 - 1440)));
    line-height: 1.5;
    margin-bottom: 0.7rem; }
    @media (max-width: 1440px) {
      .about-story__title {
        font-size: 40px; } }
    @media (min-width: 1920px) {
      .about-story__title {
        font-size: 48px; } }
    @media only screen and (max-width: 1024px) {
      .about-story__title {
        font-size: 36px;
        font-size: calc(24px + (36 - 24) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .about-story__title {
      font-size: 24px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-story__title {
      font-size: 36px; } }
    @media only screen and (max-width: 768px) {
      .about-story__title {
        text-align: center; } }
    @media only screen and (max-width: 414px) {
      .about-story__title {
        margin-bottom: 20px; } }
  .about-story__text {
    display: flex;
    font-family: 'Montserrat-Light', Helvetica, sans-serif;
    line-height: 1.47;
    letter-spacing: 0.07vmin;
    font-size: 30px;
    font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .about-story__text {
        font-size: 24px; } }
    @media (min-width: 1920px) {
      .about-story__text {
        font-size: 30px; } }
    .about-story__text:after {
      content: " ";
      display: table;
      clear: both; }
    @media only screen and (max-width: 1024px) {
      .about-story__text {
        font-size: 20px; } }
    @media only screen and (max-width: 1260px) {
      .about-story__text {
        display: block; } }
    @media only screen and (max-width: 768px) {
      .about-story__text {
        text-align: center; } }
    @media only screen and (max-width: 414px) {
      .about-story__text {
        max-height: 240px;
        overflow: hidden; }
        .about-story__text.is-full {
          max-height: none; } }
    .about-story__text p + p {
      margin-top: 0.4rem; }
  .about-story__text-left {
    width: 47%;
    padding-right: 0.8rem; }
    @media only screen and (max-width: 1260px) {
      .about-story__text-left {
        width: auto;
        padding-right: 0;
        margin-bottom: 0.4rem; } }
  .about-story__text-right {
    width: 53%; }
    @media only screen and (max-width: 1260px) {
      .about-story__text-right {
        width: auto; } }
  .about-story__text-image {
    position: relative;
    margin-bottom: 0.4rem;
    max-width: 658px; }
    @media only screen and (max-width: 1260px) {
      .about-story__text-image {
        max-width: 500px;
        float: left;
        margin-right: 1rem; } }
    @media only screen and (max-width: 768px) {
      .about-story__text-image {
        display: none; } }
    .about-story__text-image:after {
      content: '';
      position: absolute;
      bottom: 15%;
      left: 100%;
      width: 100%;
      height: 2px;
      background-image: -webkit-gradient(linear, left top, right top, from(#f419a8), color-stop(50%, #7f6fdb), to(#00f1f9));
      background-image: linear-gradient(to right, #f419a8, #7f6fdb 50%, #00f1f9); }
      @media only screen and (max-width: 1260px) {
        .about-story__text-image:after {
          content: none; } }
    .about-story__text-image img {
      width: 100%; }
  .about-story__image {
    display: none;
    overflow: hidden;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 0 auto 44px; }
    @media only screen and (max-width: 768px) {
      .about-story__image {
        display: block; } }
    @media only screen and (max-width: 414px) {
      .about-story__image {
        width: 120px;
        height: 120px; } }
    .about-story__image img {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .about-story__btn {
    margin-top: 1rem;
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 414px) {
      .about-story__btn .btn {
        width: 100%; } }
  .about-story__more {
    display: none;
    background-color: transparent;
    border: none;
    font-family: 'Montserrat-Regular', Helvetica, sans-serif;
    color: #21c4ff;
    font-size: 20px;
    position: relative;
    padding: 25px;
    margin: 0 auto; }
    @media only screen and (max-width: 414px) {
      .about-story__more {
        display: block; } }
    .about-story__more svg {
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
      -webkit-transform: rotate(90deg) translateX(-50%);
              transform: rotate(90deg) translateX(-50%);
      display: block;
      margin-left: 4px;
      height: 30px;
      width: 14px;
      fill: #21c4ff; }

.about-team {
  padding-top: 100px; }
  .about-team__text {
    font-family: 'Montserrat-Light', Helvetica, sans-serif;
    line-height: 1.47;
    letter-spacing: 0.07vmin;
    font-size: 30px;
    font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .about-team__text {
        font-size: 24px; } }
    @media (min-width: 1920px) {
      .about-team__text {
        font-size: 30px; } }
    @media only screen and (max-width: 1024px) {
      .about-team__text {
        font-size: 20px; } }
    @media only screen and (max-width: 768px) {
      .about-team__text {
        text-align: center; } }
    .about-team__text p + p {
      margin-top: 0.4rem; }
  .about-team__image {
    margin: 50px auto 40px; }
    @media only screen and (max-width: 414px) {
      .about-team__image {
        margin: 40px -46px 40px; } }
    .about-team__image img {
      max-width: 100%; }
  .about-team__list {
    margin: 40px -24px 0;
    display: flex;
    flex-wrap: wrap; }

.teammate {
  display: block;
  width: 264px;
  max-width: 100%;
  margin: 0 24px 40px; }
  @media only screen and (max-width: 600px) {
    .teammate {
      width: auto; } }
  .teammate__picture {
    margin-bottom: 14px; }
    .teammate__picture img {
      max-width: 100%; }
  .teammate__name {
    font-family: 'Montserrat-Light', Helvetica, sans-serif;
    font-size: 24px;
    line-height: 29px; }
  .teammate__position {
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif;
    font-size: 26px;
    line-height: 32px; }
  .teammate__descr {
    margin-top: 8px;
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    font-family: 'Montserrat-Light', Helvetica, sans-serif;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    line-height: 22px; }

.about-card {
  padding: 50px 40px 20px;
  height: 100%;
  position: relative;
  z-index: 1;
  min-height: 350px; }
  @media only screen and (max-width: 410px) {
    .about-card {
      padding: 50px 20px 20px;
      min-height: auto; } }
  .about-card:after, .about-card:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(255, 255, 255, 0.45)));
    background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.45)); }
  .about-card:before {
    background-image: linear-gradient(53deg, #0a050d -5%, #120e17 101%); }
  .about-card__suptitle, .about-card__subtitle {
    font-size: 30px;
    line-height: 1.47;
    margin-bottom: 10px; }
  .about-card__title {
    font-size: 80px;
    font-size: calc(40px + (80 - 40) * ((100vw - 1024px) / (1920 - 1024)));
    margin-bottom: 20px;
    font-family: 'Montserrat-SemiBold', Helvetica, sans-serif; }
    @media (max-width: 1024px) {
      .about-card__title {
        font-size: 40px; } }
    @media (min-width: 1920px) {
      .about-card__title {
        font-size: 80px; } }
    @media only screen and (max-width: 1024px) {
      .about-card__title {
        font-size: 70px;
        font-size: calc(65px + (70 - 65) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .about-card__title {
      font-size: 65px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-card__title {
      font-size: 70px; } }
    @media only screen and (max-width: 414px) {
      .about-card__title {
        font-size: 80px;
        font-size: calc(56px + (80 - 56) * ((100vw - 320px) / (414 - 320))); } }
  @media only screen and (max-width: 414px) and (max-width: 320px) {
    .about-card__title {
      font-size: 56px; } }
  @media only screen and (max-width: 414px) and (min-width: 414px) {
    .about-card__title {
      font-size: 80px; } }
    .about-card__title_sm {
      font-family: 'Montserrat-Bold', Helvetica, sans-serif;
      font-size: 44px; }
      @media only screen and (max-width: 410px) {
        .about-card__title_sm {
          font-size: 44px;
          font-size: calc(36px + (44 - 36) * ((100vw - 320px) / (414 - 320))); } }
  @media only screen and (max-width: 410px) and (max-width: 320px) {
    .about-card__title_sm {
      font-size: 36px; } }
  @media only screen and (max-width: 410px) and (min-width: 414px) {
    .about-card__title_sm {
      font-size: 44px; } }

.about-coin {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem; }
  @media only screen and (max-width: 1600px) {
    .about-coin {
      padding: 1rem 1rem; } }
  @media only screen and (max-width: 1440px) {
    .about-coin {
      padding: 1rem 0.7rem; } }
  @media only screen and (max-width: 1024px) {
    .about-coin {
      padding: 50px 46px; } }
  @media only screen and (max-width: 414px) {
    .about-coin {
      padding: 30px 16px; } }
  @media only screen and (max-width: 1280px) {
    .about-coin {
      display: block; } }
  .about-coin__content {
    width: 47%;
    padding-right: 1.4rem; }
    @media only screen and (max-width: 1280px) {
      .about-coin__content {
        width: auto;
        padding: 0;
        margin-bottom: 60px; } }
  .about-coin__title {
    font-size: 80px;
    font-size: calc(60px + (80 - 60) * ((100vw - 1440px) / (1920 - 1440)));
    font-family: 'Montserrat-ExtraBold', Helvetica, sans-serif;
    background-clip: text;
    text-fill: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, from(#00f1f9), color-stop(52%, #00f93a));
    background-image: linear-gradient(to right, #00f1f9, #00f93a 52%);
    margin-bottom: 0.2rem; }
    @media (max-width: 1440px) {
      .about-coin__title {
        font-size: 60px; } }
    @media (min-width: 1920px) {
      .about-coin__title {
        font-size: 80px; } }
    @media only screen and (max-width: 1024px) {
      .about-coin__title {
        text-align: center;
        font-size: 54px;
        font-size: calc(42px + (54 - 42) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .about-coin__title {
      font-size: 42px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-coin__title {
      font-size: 54px; } }
  .about-coin__subtitle {
    line-height: 1.42;
    font-family: 'Montserrat-Bold', Helvetica, sans-serif;
    font-size: 52px;
    font-size: calc(36px + (52 - 36) * ((100vw - 1440px) / (1920 - 1440)));
    margin-bottom: 0.2rem; }
    @media (max-width: 1440px) {
      .about-coin__subtitle {
        font-size: 36px; } }
    @media (min-width: 1920px) {
      .about-coin__subtitle {
        font-size: 52px; } }
    @media only screen and (max-width: 1024px) {
      .about-coin__subtitle {
        text-align: center;
        font-size: 34px;
        font-size: calc(30px + (34 - 30) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .about-coin__subtitle {
      font-size: 30px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-coin__subtitle {
      font-size: 34px; } }
  .about-coin__text {
    line-height: 1.47;
    font-size: 30px;
    font-size: calc(24px + (30 - 24) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .about-coin__text {
        font-size: 24px; } }
    @media (min-width: 1920px) {
      .about-coin__text {
        font-size: 30px; } }
    @media only screen and (max-width: 1024px) {
      .about-coin__text {
        text-align: center;
        font-size: 22px;
        font-size: calc(18px + (22 - 18) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .about-coin__text {
      font-size: 18px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-coin__text {
      font-size: 22px; } }
  .about-coin__items {
    width: 53%;
    display: flex;
    flex-wrap: wrap;
    margin: -15px; }
    @media only screen and (max-width: 1440px) {
      .about-coin__items {
        width: 60%; } }
    @media only screen and (max-width: 1280px) {
      .about-coin__items {
        width: auto; } }
  .about-coin__item {
    width: 50%;
    padding: 15px; }
    @media only screen and (max-width: 768px) {
      .about-coin__item {
        width: 100%; } }

.partners {
  padding: 1rem 1.5rem; }
  @media only screen and (max-width: 1600px) {
    .partners {
      padding: 1rem 1rem; } }
  @media only screen and (max-width: 1440px) {
    .partners {
      padding: 1rem 0.7rem; } }
  @media only screen and (max-width: 1024px) {
    .partners {
      padding: 50px 46px; } }
  @media only screen and (max-width: 414px) {
    .partners {
      padding: 30px 16px; } }
  .partners__headline {
    line-height: 0.6;
    margin-bottom: 1.38rem;
    font-size: 100px;
    font-size: calc(80px + (100 - 80) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .partners__headline {
        font-size: 80px; } }
    @media (min-width: 1920px) {
      .partners__headline {
        font-size: 100px; } }
    @media only screen and (max-width: 1440px) {
      .partners__headline {
        font-size: 80px;
        font-size: calc(60px + (80 - 60) * ((100vw - 1440px) / (1920 - 1440))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1440px) {
    .partners__headline {
      font-size: 60px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1920px) {
    .partners__headline {
      font-size: 80px; } }
    @media only screen and (max-width: 414px) {
      .partners__headline {
        font-size: 60px;
        font-size: calc(52px + (60 - 52) * ((100vw - 1440px) / (1920 - 1440))); } }
  @media only screen and (max-width: 414px) and (max-width: 1440px) {
    .partners__headline {
      font-size: 52px; } }
  @media only screen and (max-width: 414px) and (min-width: 1920px) {
    .partners__headline {
      font-size: 60px; } }
  .partners__items {
    display: flex;
    flex-wrap: wrap;
    margin: -15px; }
  .partners__item-wrapper {
    width: 33.33%;
    padding: 15px; }
    @media only screen and (max-width: 767px) {
      .partners__item-wrapper {
        width: 100%; } }
  .partners__item {
    background-color: #131315;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 90px 40px; }
    .partners__item img {
      max-width: 100%;
      max-height: 100%;
      height: auto; }

.about-section__in {
  padding: 2rem 1.5rem; }
  @media only screen and (max-width: 1600px) {
    .about-section__in {
      padding: 2rem 1rem; } }
  @media only screen and (max-width: 1440px) {
    .about-section__in {
      padding: 2rem 0.7rem 1rem; } }
  @media only screen and (max-width: 1024px) {
    .about-section__in {
      padding: 100px 46px; } }
  @media only screen and (max-width: 414px) {
    .about-section__in {
      padding: 100px 16px 50px; } }

.about-section__headline {
  font-family: 'Montserrat-SemiBold', Helvetica, sans-serif;
  font-size: 48px;
  font-size: calc(42px + (48 - 42) * ((100vw - 1440px) / (1920 - 1440)));
  text-align: center;
  max-width: 13rem;
  margin: 0 auto 1rem;
  line-height: 1.5; }
  @media (max-width: 1440px) {
    .about-section__headline {
      font-size: 42px; } }
  @media (min-width: 1920px) {
    .about-section__headline {
      font-size: 48px; } }
  @media only screen and (max-width: 1440px) {
    .about-section__headline {
      max-width: none;
      font-size: 42px;
      font-size: calc(20px + (42 - 20) * ((100vw - 414px) / (1440 - 414))); } }
  @media only screen and (max-width: 1440px) and (max-width: 414px) {
    .about-section__headline {
      font-size: 20px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    .about-section__headline {
      font-size: 42px; } }

.about-section__image {
  position: relative;
  width: 66.7%;
  padding-top: 41.7%;
  margin-bottom: 2rem; }
  .about-section__image img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; }
  @media only screen and (max-width: 414px) {
    .about-section__image {
      width: auto;
      padding-top: 60%;
      margin-bottom: 60px; } }
  .about-section__image:after {
    content: '';
    position: absolute;
    bottom: 20%;
    left: 100%;
    width: 100%;
    height: 2px;
    background-image: -webkit-gradient(linear, left top, right top, from(#f419a8), color-stop(50%, #7f6fdb), to(#00f1f9));
    background-image: linear-gradient(to right, #f419a8, #7f6fdb 50%, #00f1f9); }
    @media only screen and (max-width: 414px) {
      .about-section__image:after {
        content: none; } }

.news-about {
  min-height: auto;
  overflow: hidden;
  padding: 1rem 1.5rem; }
  @media only screen and (max-width: 1600px) {
    .news-about {
      padding: 1rem 1rem; } }
  @media only screen and (max-width: 1440px) {
    .news-about {
      padding: 1rem 0.7rem; } }
  @media only screen and (max-width: 1024px) {
    .news-about {
      padding: 50px 46px; } }
  @media only screen and (max-width: 414px) {
    .news-about {
      padding: 30px 16px; } }
  .news-about__headline {
    line-height: 1;
    margin-bottom: 2.38rem;
    font-size: 100px;
    font-size: calc(80px + (100 - 80) * ((100vw - 1440px) / (1920 - 1440))); }
    @media (max-width: 1440px) {
      .news-about__headline {
        font-size: 80px; } }
    @media (min-width: 1920px) {
      .news-about__headline {
        font-size: 100px; } }
    @media only screen and (max-width: 1024px) {
      .news-about__headline {
        margin-bottom: 4rem;
        font-size: 60px;
        font-size: calc(52px + (60 - 52) * ((100vw - 414px) / (1024 - 414))); } }
  @media only screen and (max-width: 1024px) and (max-width: 414px) {
    .news-about__headline {
      font-size: 52px; } }
  @media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .news-about__headline {
      font-size: 60px; } }
    @media only screen and (max-width: 414px) {
      .news-about__headline {
        margin-bottom: 2rem; } }

.about {
  background-color: #000000;
  position: relative;
  min-height: 100vh; }
  @media only screen and (max-width: 1024px) {
    .about {
      min-height: auto; } }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("/fonts/Montserrat-Bold.woff2") format("woff2"), url("/fonts/Montserrat-Bold.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url("/fonts/Montserrat-SemiBold.woff2") format("woff2"), url("/fonts/Montserrat-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat-Light';
  src: url("/fonts/Montserrat-Light.woff2") format("woff2"), url("/fonts/Montserrat-Light.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat-Medium';
  src: url("/fonts/Montserrat-Medium.woff2") format("woff2"), url("/fonts/Montserrat-Medium.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat-Hairline';
  src: url("/fonts/Montserrat-Hairline.woff2") format("woff2"), url("/fonts/Montserrat-Hairline.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat-Regular';
  src: url("/fonts/Montserrat-Regular.woff2") format("woff2"), url("/fonts/Montserrat-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url("/fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("/fonts/Montserrat-ExtraBold.woff") format("woff"); }

:root {
  --line-width: 10px; }

* {
  box-sizing: border-box; }

html {
  font-size: 100px;
  font-size: calc(80px + (100 - 80) * ((100vw - 1440px) / (1920 - 1440))); }
  @media (max-width: 1440px) {
    html {
      font-size: 80px; } }
  @media (min-width: 1920px) {
    html {
      font-size: 100px; } }
  @media only screen and (max-width: 1440px) {
    html {
      font-size: 80px;
      font-size: calc(50px + (80 - 50) * ((100vw - 1024px) / (1440 - 1024))); } }
  @media only screen and (max-width: 1440px) and (max-width: 1024px) {
    html {
      font-size: 50px; } }
  @media only screen and (max-width: 1440px) and (min-width: 1440px) {
    html {
      font-size: 80px; } }

body {
  color: #fff;
  font-size: 16px;
  background-color: #08050d; }
  @media only screen and (max-width: 1024px) {
    body.is-mob-menu-opened {
      overflow: hidden; }
    body.is-blocked {
      height: 100vh;
      height: calc(1 * 100);
      height: calc(var(--vh, 1) * 100);
      overflow: hidden;
      width: 100%; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-family: 'Montserrat-Bold', Helvetica, sans-serif; }

section {
  min-height: 100vh; }

.app {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(95%, #140e1c), color-stop(13%, #08060a), color-stop(-4%, #000000), color-stop(-31%, #120e17));
  background-image: linear-gradient(to bottom, #140e1c 95%, #08060a 13%, #000000 -4%, #120e17 -31%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Montserrat-Light', Helvetica, sans-serif; }
  .app__body {
    flex-grow: 1;
    min-height: 100%;
    overflow: hidden; }

.main-overlay {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(95%, #140e1c), color-stop(13%, #08060a), color-stop(-4%, #000000), color-stop(-31%, #120e17));
  background-image: linear-gradient(to bottom, #140e1c 95%, #08060a 13%, #000000 -4%, #120e17 -31%); }

