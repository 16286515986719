@import 'style/helpers';

.menu-mob {
  $this: &;
  background-image: linear-gradient(200deg, #140e1c 90%, #08060a 36%, #000000 25%, #120e17 7%);
  display: none;
  height: 100vh;
  height: calc(var(--vh, 1) * 100);
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  pointer-events: auto;

  @include md {
    .is-mob-menu-opened & {
      display: block; } }

  &__in {
    display: flex;
    flex-direction: column;
    min-height: 100%; }

  &__body {
    display: flex;
    flex-grow: 1;
    padding: 70px 30px;
    justify-content: center; }

  &__footer {
    align-items: center;
    background-color: #201f27;
    display: flex;
    flex-direction: column;
    padding: 36px 10px; }

  &__btn {
    margin-bottom: 24px; } }
