@import 'style/helpers';

.tooltip {
  color: #000;
  cursor: default;
  left: 50%;
  line-height: 1.3;
  position: absolute;
  top: calc(100% + 16px);
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 2;
  @include css-lock(14, 16, $desktop, $desktopHD);
  @include bold;

  @include lg {
    @include css-lock(12, 14, $tablet, $desktop); }

  &__in {
    background-image: linear-gradient(to right, #ffeb4f, #00e300);
    border-radius: 27px;
    padding: 8px 22px;

    @include lg {
      padding: 5px 18px; }

    &:before {
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid #65e620;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: -8px;
      transform: translateX(-50%);
      z-index: -1;

      @include lg {
        border-left-width: 14px;
        border-right-width: 14px;
        border-bottom-width: 14px; } } } }
