@import 'style/helpers';

$screenHeight: 79.6vmin;

.screen {
  $this: &;
  background-image: linear-gradient(144deg, #2f2c38 8%, #000000 109%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: $screenHeight;
  overflow: hidden;
  position: absolute;
  right: 4vmin;
  width: 58vmin;
  z-index: -1;

  @include md {
    right: 50%;
    margin-right: -29vmin; }

  &__bar {
    background-image: url('/img/demo/phone-bar.svg');
    background-size: 85% auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 2vmin;
    position: relative;
    top: 2vmin;
    width: 100%;
    z-index: 1; }

  &__circle {
    background-image: linear-gradient(to bottom, rgba(134, 133, 140, 0.3), #86858c);
    border-radius: 50%;
    height: 42vmin;
    left: 50%;
    margin-left: -21vmin;
    margin-top: -22vmin;
    opacity: 0.14;
    position: absolute;
    top: 50%;
    width: 42vmin;

    &_back {
      background-image: linear-gradient(144deg, #4D128B 0%, #9C53B7 100%);
      height: 100%;
      top: 0;
      left: -19%;
      margin-left: 0;
      margin-top: 0;
      opacity: 1;
      width: $screenHeight; } }

  &__headline {
    display: flex;
    font-size: 2.5vmin;
    flex-grow: 1;
    letter-spacing: 0.07vmin;
    line-height: 1.5;
    min-height: 9vmin;
    padding: 5.58vmin 3vmin 0;
    position: relative;
    justify-content: space-between;
    z-index: 2;
    @include medium;

    .is-sixth-screen & {
      background-image: url('/img/demo/input.svg');
      background-position: top 5vmin center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 90% auto;

      #{$this} {
        &__btn,
        &__title,
        &__avatar {
          opacity: 0;
          visibility: hidden; } } } }

  &__title {
    height: 1.2em;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;

    span {
      display: block; }

    .is-third-screen & {
      span {
        transform: translateY(-100%); } }

    .is-seventh-screen & {
      span {
        transform: translateY(-200%); } } }

  &__footer {
    align-items: center;
    display: flex;
    position: relative;
    justify-content: space-between; }

  &__btn,
  &__avatar {
    background-size: contain;
    background-repeat: no-repeat; }

  &__btn {
    background-image: url('/img/demo/arrow-left.svg');
    height: 3vmin;
    padding-right: 2.5vmin;
    width: 1.5vmin; }

  &__avatar {
    background-image: url('/img/demo/avatar.svg');
    height: 4vmin;
    position: relative;
    width: 4vmin;

    &:before {
      background-image: url('/img/demo/avatar-colored.svg');
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity ease-out 0.3s, visibility ease-out 0.3s;
      visibility: hidden;
      width: 100%; }

    &.is-colored {
      &:before {
        opacity: 1;
        visibility: visible; } } }

  &__curtain {
    background-color: #3c176b;
    height: 14vmin;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }

  &__balance {
    height: 100%;
    left: 0;
    position: absolute;
    top: 63vmin;
    transform: translateY(-18%);
    width: 100%;

    &:before,
    &:after {
      background-size: 97% auto;
      background-repeat: no-repeat;
      background-position: bottom center;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: opacity ease-out 0.3s;
      width: 100%; }

    .is-first-screen & {
      &:before {
        background-image: url('/img/demo/screen-1.svg');
        background-size: 100% auto;
        background-position: bottom center;
        opacity: 1; }

      &:after {
        opacity: 0; }

      #{$this}__item_first {
        opacity: 1;
        visibility: visible; } }

    .is-second-screen & {
      &:before {
        opacity: 0; }

      &:after {
        background-image: url('/img/demo/screen-2.svg');
        background-size: 99% auto;
        background-position: bottom center;
        opacity: 1; }

      #{$this}__avatar {
        &_colored {
          &:before {
            opacity: 1; } } }

      #{$this}__item_second {
        opacity: 1;
        visibility: visible; } }

    .is-third-screen & {
      &:before {
        background-image: none;
        background-size: cover;
        opacity: 1; }

      &:after {
        opacity: 0; }

      #{$this}__item_third {
        opacity: 1;
        visibility: visible; } }

    .is-fourth-screen & {
      &:before {
        opacity: 0; }

      &:after {
        background-image: url('/img/demo/screen-4.svg');
        background-size: cover;
        opacity: 1; }

      #{$this}__item_sixth {
        opacity: 1;
        visibility: visible; } }

    .is-fifth-screen & {
      background-image: url('/img/demo/screen-5.svg');
      background-size: 101% auto;
      background-position: bottom center;

      #{$this}__item_last {
        opacity: 1;
        visibility: visible; } } }

  &__item {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    &_last {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      opacity: 0;
      padding-top: 13vmin;
      text-align: center;
      visibility: hidden;
      justify-content: center; } }

  &__price,
  &__market-cap,
  &__eex,
  &__eex-title,
  &__status,
  &__eex-status-title,
  &__btc,
  &__eex-btn,
  &__eex-price,
  &__send-money-btn,
  &__send-money-cancel,
  &__done-headline,
  &__done-status,
  &__done-btn,
  &__done-ok,
  &__eex-price-title,
  &__market-cap-title,
  &__date {
    display: inline-block;
    position: absolute;
    @include bold; }

  &__price {
    font-size: 3vmin;
    right: 7vmin;
    top: 21vmin; }

  &__market-cap {
    font-size: 2.6vmin;
    left: 5vmin;
    top: 42.5vmin; }

  &__eex {
    font-size: 4.6vmin;
    line-height: 1.26;
    letter-spacing: 0.08vmin;
    left: 3.58vmin;
    top: 22.85vmin; }

  &__eex-title {
    font-size: 3vmin;
    left: 3.2vmin;
    opacity: 0.6;
    top: 19.3vmin;
    @include medium; }

  &__status {
    width: 100%; }

  &__eex-status-title {
    font-size: 3vmin;
    left: 5.4vmin;
    letter-spacing: 0.03vmin;
    @include semi-bold;

    &_first {
      top: 34.3vmin; }

    &_second {
      top: 50.3vmin; }

    &_third {
      top: 66.3vmin; } }

  &__btc {
    color: #5b5662;
    font-size: 2vmin;
    letter-spacing: 0.0275vmin;
    right: 12.7vmin;
    @include semi-bold;

    &_first {
      top: 36.8vmin; }

    &_second {
      top: 60.8vmin; } }

  &__status {
    padding: 0 8.2vmin 0 5.5vmin;

    &_first,
    &_second,
    &_third {
      display: flex;
      justify-content: space-between;

      span {
        &:nth-child(1) {
          font-size: 1.83vmin;
          letter-spacing: 0.03vmin;
          opacity: 0.61;
          @include medium; }

        &:nth-child(2) {
          color: #290f4b;
          font-size: 1.5vmin;
          letter-spacing: 0.0875vmin;
          text-transform: uppercase; } } }

    &_first {
      top: 39.4vmin; }

    &_second {
      top: 55.5vmin; }

    &_third {
      top: 71.5vmin; } }

  &__price,
  &__market-cap {
    color: #ffe08c; }

  &__eex {
    color: #ffffff; }

  &__eex-btn {
    font-size: 2.16vmin;
    letter-spacing: 0.0325vmin;
    text-transform: uppercase;

    &_buy {
      right: 12.6vmin;
      top: 45.5vmin; }

    &_sell {
      right: 12.2vmin;
      top: 69.8vmin; } }

  &__eex-price {
    font-size: 2vmin;
    left: 5.6vmin;
    letter-spacing: 0.03vmin;
    @include medium;

    &_first {
      top: 45.55vmin; }

    &_second {
      top: 69.75vmin; } }

  &__logo {
    height: 20vmin;
    margin: 0 auto 8.3vmin;
    position: relative;
    transform: scale(0.6);
    width: 20vmin;

    &:before,
    &:after {
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: opacity ease-out 0.3s, visibility ease-out 0.3s;
      width: 100%; }

    &:before {
      background-image: url('/img/demo/fish.svg'); }

    &:after {
      background-image: url('/img/demo/dragon.png');
      transform: translateX(1.5vmin);
      opacity: 0;
      visibility: hidden; }

    &.is-updated {
      &:before {
        opacity: 0;
        visibility: hidden; }

      &:after {
        opacity: 1;
        visibility: visible; } } }

  &__line {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 1.6vmin;
    height: 1.3vmin;
    overflow: hidden;
    position: relative;
    width: 40vmin; }

  &__progress {
    background-image: linear-gradient(to right, #ffeb4f, #00e300);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

  &__status-title {
    font-size: 3.5vmin;
    line-height: 1.69;
    letter-spacing: 0.47px;
    margin-bottom: 5.6vmin;
    @include semi-bold; }

  &__points-title {
    font-size: 1.83vmin;
    line-height: 3;
    opacity: 0.6; }

  &__point {
    font-size: 4.3vmin;
    line-height: 1.37;
    letter-spacing: 0.93px;
    margin-bottom: 3vmin;
    @include semi-bold; }

  &__search-footer {
    align-items: center;
    background-color: rgba(#320867, 0.5);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 15vmin;
    left: 0;
    position: absolute;
    text-align: center;
    justify-content: center;
    width: 100%;

    span {
      display: block;

      &:first-child {
        font-size: 3.6vmin;
        margin-bottom: 1.5vmin;
        @include semi-bold; }

      &:last-child {
        font-size: 2.1vmin;
        opacity: 0.6; } } }

  &__search-icon {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.05) 100%);
    border-radius: 50%;
    height: 16.6vmin;
    left: 50%;
    position: absolute;
    top: 59%;
    transform: translate(-50%, -50%);
    width: 16.6vmin;

    &:before,
    &:after {
      border: 0.25vmin solid rgba(255, 255, 255, 0.15);
      border-radius: 50%;
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); }

    &:before {
      height: 33.3vmin;
      width: 33.3vmin; }

    &:after {
      height: 55vmin;
      width: 55vmin; }

    svg {
      display: block;
      left: 56%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 6.25vmin; } }

  &__user {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 8.3vmin;
    position: absolute;
    width: 8.3vmin;

    span {
      bottom: -2vmin;
      font-size: 2vmin;
      line-height: 1.3;
      left: 50%;
      position: absolute;
      text-align: center;
      transform: translate(-50%, 100%);
      min-width: 100%;
      @include medium; }

    &_first {
      background-image: url('/img/demo/user-1.svg');
      left: 6vmin;
      top: 23vmin; }

    &_second {
      background-image: url('/img/demo/user-2.svg');
      right: 8vmin;
      top: 44vmin; } }

  &__send-money,
  &__done {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    svg {
      display: block;
      width: 100%; } }

  &__circles {
    left: 50%;
    position: absolute;
    top: calc(50% + 5.5vmin); }

  &__send-money-btn {
    bottom: 7.6vmin;
    font-size: 2.1vmin;
    left: 50%;
    letter-spacing: 0.1vmin;
    transform: translateX(-50%);
    text-transform: uppercase;
    text-shadow: 0 1px 10px rgba(0, 0, 0, 0.32);
    @include bold; }

  &__send-money-cancel {
    bottom: 2vmin;
    font-size: 2vmin;
    left: 50%;
    letter-spacing: 0.001vmin;
    opacity: 0.6;
    transform: translateX(-50%);
    @include medium; }

  &__done-headline,
  &__done-status,
  &__done-btn,
  &__done-ok {
    left: 50%;
    transform: translateX(-50%); }

  &__done-headline {
    font-size: 3vmin;
    letter-spacing: 0.1vmin;
    opacity: 0.6;
    top: 18.2vmin;
    @include light; }

  &__done-status {
    font-size: 2.4vmin;
    letter-spacing: 0.1vmin;
    top: 53vmin; }

  &__done-btn {
    bottom: 10.4vmin;
    font-size: 2.2vmin;
    letter-spacing: 0.01vmin;
    text-transform: uppercase; }

  &__done-ok {
    bottom: 5vmin;
    font-size: 2vmin;
    opacity: 0.6;
    @include light; }

  &__eex-price-title,
  &__market-cap-title {
    font-size: 3vmin;
    left: 5.3vmin;
    letter-spacing: 0.03vmin;
    top: 21vmin; }

  &__market-cap-title {
    top: 37.3vmin; }

  &__date {
    bottom: 27vmin;
    font-size: 2vmin;
    display: flex;
    padding: 0 10vmin;
    justify-content: space-between;
    width: 100%;
    @include light;

    span {
      display: block; } }

  .is-es & {
    .screen {
      &__status {
        padding-right: 7.5vmin; }

      &__eex-btn_sell {
        right: 10.2vmin; }

      &__eex-btn_buy {
        right: 9vmin; }

      &__eex-price {
        &_first {
          top: 45.8vmin; }
        &_second {
          top: 70.2vmin; } }

      &__btc {
        &_first {
          top: 37vmin; }
        &_second {
          top: 61.3vmin; } }

      &__date {
        bottom: 26vmin;
        text-align: center; } } }

  .is-pt & {
    .screen {
      &__status {
        padding-right: 7.2vmin; }

      &__eex-btn_sell {
        right: 10vmin; }

      &__eex-btn_buy {
        right: 9vmin; }

      &__btc {
        width: 9vmin;

        &_first {
          top: 36vmin; }
        &_second {
          top: 60.2vmin; } }

      &__date {
        bottom: 26vmin;
        text-align: center; } } }

  .is-jp & {
    .screen {
      &__status {
        padding-right: 10vmin;

        &_first {
          padding-right: 11.5vmin; } }

      &__eex-btn_sell {
        right: 12.6vmin; }

      &__btc {
        &_first {
          top: 37vmin; }
        &_second {
          top: 61.2vmin; } } } }

  .is-kr & {
    .screen {
      &__status {
        padding-right: 11.4vmin;

        &_first {
          padding-right: 11.6vmin; } }

      &__eex-btn {
        right: 13vmin; } } }

  .is-ru & {
    .screen {
      &__status {
        &_second,
        &_third {
          padding-right: 5.8vmin; } }

      &__eex-btn {
        &_buy {
          right: 10vmin; }

        &_sell {
          right: 9vmin; } } } } }
