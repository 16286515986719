@import 'style/helpers';

.details-header {
  $this: &;

  display: flex;
  align-items: center;
  margin: 0 0 15vmin;
  @include md {
    display: block;
    margin-bottom: 50px; }

  &.is-column {
    display: block;
    #{$this}__back {
      margin-bottom: 4vmin; } }

  svg {
    transform: rotate(180deg);
    width: 7vmin;
    margin-right: 7vmin; }


  &__title {
    //max-width: 80%
    font-size: 10vmin;
    color: #ffffff;
    @include bold;
    @include md {
      max-width: none;
      font-size: 30px;
      line-height: 1.5; } }

  &__back {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    @include md {
      margin-bottom: 30px; }

    svg {
      transform: rotate(180deg);
      width: 7vmin;
      margin-right: 7vmin;
      @include md {
        margin-right: 40px;
        width: 27px; } } } }
