@import 'style/helpers';

.vacancies-details {
  position: relative;
  transform: translateX(-100%);
  transition: transform ease-in 0.5s, opacity ease-in 0.9s;
  opacity: 0;
  @include md {
    padding: 0 16px; }

  &__in {
    display: flex;
    align-items: flex-start;
    width: 100%; }

  &__body {
    flex-grow: 1; }

  &__btn {
    margin-top: 14vmin;
    .btn {
      padding: 0.25rem 0.6rem;
      @include sm {
        width: 100%;
        padding: 0.25rem 0.15rem; } } }

  &__sidebar {
    margin-left: 110px;
    width: 100%;
    max-width: 450px;
    flex-shrink: 0;
    padding-top: 12vmin;
    @include lg {
      max-width: 360px;
      margin-left: 80px; }
    @include md {
      display: none; } }


  &.enter-active,
  &.enter-done {
    transform: translateX(0);
    opacity: 1; }

  &.enter,
  &.exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    opacity: 0; }

  &.exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0s; } }
