.circles {
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transition: opacity linear 0.3s, visibility linear 0.3s;
  visibility: visible;

  span {
    border: 0.3vmin solid rgba(#ffffff, 0.1);
    border-radius: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform linear 0.6s;

    &:nth-child(1) {
      height: 35vmin;
      width: 35vmin; }

    &:nth-child(2) {
      height: 63vmin;
      width: 63vmin; }

    &:nth-child(3) {
      height: 91vmin;
      width: 91vmin; }

    &:nth-child(4) {
      height: 119vmin;
      width: 119vmin; }

    &:nth-child(5) {
      height: 170vmin;
      width: 170vmin; } }

  .is-circles-animating & {
    span {
      transform: translate(-50%, -50%) scale(1); } }

  .is-circles-hidden & {
    opacity: 0;
    visibility: hidden; } }
