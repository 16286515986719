@import 'style/helpers';

.about-story {
	$this: &;
	@include padding-sm;
	&__title {
		@include semi-bold;
		@include css-lock(40, 48, $desktop, $desktopHD);
		line-height: 1.5;
		margin-bottom: 0.7rem;
		@include md {
			@include css-lock(24, 36, $mob, $tablet); }
		@include r(768) {
			text-align: center; }
		@include sm {
			margin-bottom: 20px; } }
	&__text {
		display: flex;
		@include light;
		line-height: 1.47;
		letter-spacing: 0.07vmin;
		@include css-lock(24, 30, $desktop, $desktopHD);
		&:after {
			content: " ";
			display: table;
			clear: both; }
		@include md {
			font-size: 20px; }
		@include r(1260) {
			display: block; }
		@include r(768) {
			text-align: center; }
		@include sm {
			max-height: 240px;
			overflow: hidden;
			&.is-full {
				max-height: none; } }
		p + p {
			margin-top: 0.4rem; } }
	&__text-left {
		width: 47%;
		padding-right: 0.8rem;
		@include r(1260) {
			width: auto;
			padding-right: 0;
			margin-bottom: 0.4rem; } }
	&__text-right {
		width: 53%;
		@include r(1260) {
			width: auto; } }
	&__text-image {
		position: relative;
		margin-bottom: 0.4rem;
		max-width: 658px;
		@include r(1260) {
			max-width: 500px;
			float: left;
			margin-right: 1rem; }
		@include r(768) {
			display: none; }
		&:after {
			content: '';
			position: absolute;
			bottom: 15%;
			left: 100%;
			width: 100%;
			height: 2px;
			background-image: linear-gradient(to right, #f419a8, #7f6fdb 50%, #00f1f9);
			@include r(1260) {
				content: none; } }
		img {
			width: 100%; } }
	&__image {
		display: none;
		overflow: hidden;
		border-radius: 50%;
		width: 200px;
		height: 200px;
		margin: 0 auto 44px;
		@include r(768) {
			display: block; }
		@include sm {
			width: 120px;
			height: 120px; }
		img {
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover; } }
	&__btn {
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		@include sm {
			.btn {
				width: 100%; } } }
	&__more {
		display: none;
		background-color: transparent;
		border: none;
		@include regular;
		color: #21c4ff;
		font-size: 20px;
		position: relative;
		padding: 25px;
		margin: 0 auto;
		@include sm {
			display: block; }
		svg {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform-origin: 0 50%;
			transform: rotate(90deg) translateX(-50%);
			display: block;
			margin-left: 4px;
			height: 30px;
			width: 14px;
			fill: #21c4ff; } } }

.about-team {
	padding-top: 100px;
	&__text {
		@include light;
		line-height: 1.47;
		letter-spacing: 0.07vmin;
		@include css-lock(24, 30, $desktop, $desktopHD);

		@include md {
			font-size: 20px; }
		@include r(768) {
			text-align: center; }
		p + p {
			margin-top: 0.4rem; } }

	&__image {
		margin: 50px auto 40px;
		@include sm {
			margin: 40px -46px 40px; }
		img {
			max-width: 100%; } }
	&__list {
		margin: 40px -24px 0;
		display: flex;
		flex-wrap: wrap; } }

.teammate {
	display: block;
	width: 264px;
	max-width: 100%;

	margin: 0 24px 40px;
	@include r(600) {
		width: auto; }

	&__picture {
		margin-bottom: 14px;
		img {
			max-width: 100%; } }
	&__name {
		@include light;
		font-size: 24px;
		line-height: 29px; }
	&__position {
		@include semi-bold;
		font-size: 26px;
		line-height: 32px; }
	&__descr {
		margin-top: 8px;
		padding-top: 10px;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		@include light;
		color: rgba(255, 255, 255, 0.8);
		font-size: 18px;
		line-height: 22px; } }
