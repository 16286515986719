.socials {
  $this: &;

  &__item {
    background-color: rgba(#979797, 0.1);
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    transition: background-color ease-out 0.3s;

    a {
      position: relative; }

    &:not(:last-of-type) {
      margin-right: 0.3rem; }

    &:before {
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity ease-in 0.3s;
      width: 100%; }

    &:hover {
      &:before {
        opacity: 1; } }

    &_facebook {
      #{$this}__icon {
        background-size: auto 40%; }

      &:before {
        background-image: linear-gradient(to bottom, #4064ad, #305297); } }

    &_linkedin {
      &:before {
        background-image: linear-gradient(to bottom, #0271ae, #046092); } }

    &_telegram {
      &:before {
        background-image: linear-gradient(to bottom, #37a8d9, #198cbe); } }

    &_twitter {
      &:before {
        background-image: linear-gradient(to bottom, #01baf7, #068bb7); } }

    &_youtube {
      &:before {
        background-image: linear-gradient(to bottom, #f90200, #ca0c0a); } } }

  &__icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40% auto;
    display: block;
    height: 0.6rem;
    width: 0.6rem; } }
