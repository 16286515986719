@import 'style/helpers';

.join-us {
  min-height: auto;
  @include padding;

  @include md {
    display: none; }

  &__headline {
    line-height: 0.6;
    margin-bottom: 1.38rem;
    @include css-lock(80, 100, $desktop, $desktopHD);

    @include lg {
      @include css-lock(60, 80, $desktop, $desktopHD); } }

  &__row {
    align-items: stretch;
    display: flex; }

  &__col {
    width: 100%;

    &:not(:last-of-type) {
      margin-right: 0.6rem; } }

  &__title {
    letter-spacing: 0.25px;
    margin-bottom: 0.42rem;
    @include css-lock(24, 30, $desktop, $desktopHD);
    @include light;

    @include lg {
      @include css-lock(18, 24, $tablet, $desktop); } }

  &__socials {
    transform: translateY(25%); } }
