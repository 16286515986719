@import 'style/helpers';

.hero {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 0;
  @include padding;
  padding-top: 26.5vmin;

  @include sm {
    padding-bottom: 80px;
    padding-top: 80px; }

  &__block {
    max-width: 60%;

    @include md {
      margin: auto;
      max-width: 540px;
      text-align: center; } }

  &__particle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

  &__title {
    @include css-lock(88, 112, $desktop, $desktopHD);

    @include lg {
      @include css-lock(64, 88, $tablet, $desktop); }

    @include md {
      line-height: 1.46;
      @include css-lock(48, 64, $mob, $tablet); }

    @include sm {
      font-size: 36px; } }

  &__subtitle {
    letter-spacing: 0.016em;
    position: relative;
    top: -15px;
    @include css-lock(48, 60, $desktop, $desktopHD);
    @include semi-bold;

    @include lg {
      @include css-lock(36, 48, $tablet, $desktop); }

    @include md {
      line-height: 1.46;
      @include css-lock(24, 36, $mob, $tablet); }

    @include sm {
      font-size: 18px;
      margin-bottom: 20px;
      top: 0; } }

  &__text {
    line-height: 1.47;
    margin-bottom: 0.8rem;
    max-width: 600px;
    @include css-lock(24, 30, $desktop, $desktopHD);

    @include md {
      letter-spacing: -0.18px;
      line-height: 1.63;
      margin-bottom: 60px;
      opacity: 0.59;
      @include css-lock(16, 24, $mob, $tablet); }

    @include sm {
      font-size: 14px;
      margin-bottom: 30px; } }

  &__coin {
    width: 43%;

    @include md {
      display: none; }

    svg {
      display: block;
      margin: auto;
      max-width: 675px;
      width: 100%;

      #c {
        stop {
          &:nth-child(1) {
            animation: colors 6s infinite ease-in-out; }
          &:nth-child(2) {
            animation: colors 6s infinite 6s ease-in-out; } } } } } }

@keyframes colors {
  20% {
    stop-color: #4C00DD; }
  60% {
    stop-color: #9527CC; }
  90% {
    stop-color: #4C00DD; } }
