@import 'style/helpers';

$curtain: 100px;
$curtainTablet: 70px;

.video {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  @include padding;

  @include md {
    padding-top: 322px;
    text-align: center;
    justify-content: center; }

  &__in {
    width: 100%;

    @include md {
      width: auto; } }

  &__media {
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat;

    @include md-land {
      background-image: url('/img/video-landscape.png'); }

    @include md-port {
      background-image: url('/img/video-portrait.png'); } }

  &__play-btn {
    position: absolute;
    top: 50vh;
    top: calc(var(--vh, 1) * 50);
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ffffff;
    border-radius: 40px;
    cursor: pointer;
    height: 44px;
    width: 80px;
    &:before {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 18px solid #ffffff;
      content: '';
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity ease-out 0.3s; } }


  &__content {
    max-width: 890px;
    position: relative;
    width: 50%;
    z-index: 2;

    @include md {
      width: 100%; } }

  &__headline {
    line-height: 1.2;
    letter-spacing: 1px;
    margin-bottom: 0.65rem;
    @include css-lock(80, 100, $desktop, $desktopHD);
    @include bold;

    @include lg {
      @include css-lock(60, 80, $tablet, $desktop); }

    @include md {
      line-height: 1.31;
      margin-bottom: 30px;
      @include css-lock(52, 60, $mob, $tablet); }

    @include sm {
      font-size: 32px; } }

  &__text {
    line-height: 1.5;
    letter-spacing: 0.4px;
    @include css-lock(32, 40, $desktop, $desktopHD);

    @include lg {
      @include css-lock(26, 32, $tablet, $desktop); }

    @include md {
      @include css-lock(20, 26, $mob, $tablet); }

    @include sm {
      font-size: 16px; }

    p {
      &:not(:last-of-type) {
        margin-bottom: 20px; } } }

  &__item,
  &__media {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

  &__item {
    z-index: -1;
    object-fit: cover; }

  &__curtain {
    z-index: 1;
    background-color: #000;
    position: absolute;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      height: $curtain;
      left: 0;
      width: 100%;

      @include lg {
        height: $curtainTablet; } }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      height: 100%;
      width: $curtain;

      @include lg {
        width: $curtainTablet; } }

    &:nth-of-type(1) {
      top: 0; }

    &:nth-of-type(2) {
      bottom: 0; }

    &:nth-of-type(3) {
      left: 0; }

    &:nth-of-type(4) {
      right: 0; } }

  &__shadow {
    z-index: 1;
    background-color: rgba(2, 0, 8, 0.6);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }
