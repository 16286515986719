@import 'style/helpers';

.faq {
  background-image: linear-gradient(213deg, #000000 0%, #140e1c 23%, #000000 75%);
  position: relative;
  min-height: 100vh;
  @include md {
    min-height: auto; }

  &__in {
    @include padding;
    @include md {
      padding: 130px 0 90px; } }



  //.details
  //  &__header
  //    display: block
  //  &__back
  //    margin-bottom: 4vmin
  //  &__sidebar
  //    padding-top: 13vmin


  //.details
  //  &__header
  //    width: 80vw
  //  &__sidebar
  //    padding-top: 35vmin
 }  //    //padding-top: 300px
