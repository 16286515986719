@import 'style/helpers';

.benefits {
  background-image: url('/img/bg.jpg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: calc(var(--vh, 1) * 100);

  &__row {
    display: flex;
    margin-bottom: 20vmin;
    width: 100%;

    @include md {
      display: contents; } }

  &__item {
    align-items: center;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include padding;

    @include md-port {
      flex-direction: column;
      padding-top: 25vmin;
      padding-bottom: 25vmin; } }

  &__headline,
  &__text {
    width: 50%;

    @include md {
      width: 100%; } }

  &__header {
    @include md {
      flex-grow: 1;
      order: 1;
      width: 100%; } }

  &__headline {
    font-size: 8vmin;
    line-height: 1.1;
    letter-spacing: 0.26vmin;
    position: relative;
    background-clip: text;
    text-fill: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include medium;

    @include lg {
      font-size: 6vmin; }

    @include md {
      font-size: 6vmin;
      margin-bottom: 7.5vmin;
      padding-left: 2vmin; }

    @include sm {
      font-size: 8.2vmin; }

    &_violet {
      background-image: linear-gradient(69deg, #f419a8 6%, #7f6fdb 40%, #00f1f9 73%); }

    &_pink {
      background-image: linear-gradient(70deg, #ff2dd6 10%, #f12d2d 49%, #e1f701 85%); }

    &_green {
      background-image: linear-gradient(to right, #ff854f 6%, #ffeb4f 43%, #00e300 80%); }

    &_blue {
      background-image: linear-gradient(78deg, #7f6fdb 7%, #00f1f9 57%, #00f95f 99%); }

    &_dark-blue {
      background-image: linear-gradient(76deg, #00f1f9 7%, #7f6fdb 54%, #f419a8 102%); }

    &_orange {
      background-image: linear-gradient(73deg, #00e300 -2%, #ffeb4f 44%, #ff7649 92%); }

    span {
      background-image: inherit;
      display: inline-block;
      max-width: 770px;
      background-clip: text;
      text-fill: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        left: 0;
        position: absolute;
        top: 0;
        transform: translate(0, 0);
        transition: transform ease-out 0.3s, visibility ease-out 0.15s;
        visibility: hidden;
        min-width: 100%;
        @include hairline; }

      &:nth-of-type(1) {
        opacity: 0.1; }

      &:nth-of-type(2) {
        opacity: 0.3; } }

    .is-animated & {
      span {
        visibility: visible;

        &:nth-of-type(1) {
          transform: translate(-0.8rem, -0.7rem);
          @include md {
            transform: translate(-16px, -18px); } }

        &:nth-of-type(2) {
          transform: translate(-0.4rem, -0.5rem);
          @include md {
            transform: translate(-10px, -12px); } } } } }

  &__text {
    line-height: 1.5;
    margin-left: 15vmin;
    max-width: 730px;
    @include css-lock(24, 30, $desktop, $desktopHD);
    @include regular;

    @include lg {
      @include css-lock(20, 24, $tablet, $desktop); }

    @include md {
      font-size: 2.8vmin;
      order: 3;
      margin-left: 0;
      max-width: 100%; }

    @include sm {
      font-size: 3.6vmin; } }

  &__icons {
    width: 100%;

    @include md {
      margin-bottom: 38px;
      order: 2; } }

  &__icon {
    display: inline-block;
    height: 0.8rem;

    img {
      height: 100%;
      display: block; }

    @include sm {
      height: 20px; }

    &:not(:last-of-type) {
      margin-right: 0.6rem; } }

  &__video,
  &__video video {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include md {
      display: none; } }

  &__video {
    video {
      object-fit: cover; }

    &:after {
      background-color: rgba(2, 0, 8, 0.2);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; } }

  &__promo {
    display: none;

    @include md {
      display: block; } }

  &__played-video {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    video {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; } }

  &__play-btn {
    border: 1px solid #ffffff;
    border-radius: 40px;
    cursor: pointer;
    height: 44px;
    position: relative;
    width: 80px;

    &.is-active {
      &:before {
        opacity: 0; }

      &:after {
        opacity: 1; } }

    &:before {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 18px solid #ffffff;
      content: '';
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity ease-out 0.3s; }

    &:after {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url('/img/icons/pause.svg');
      content: '';
      height: 40px;
      left: 50%;
      position: absolute;
      top: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: opacity ease-out 0.3s;
      width: 40px; } } }
