@import 'style/helpers';

.details-content {

  max-width: 1020px;
  width: 100%;
  color: #a1a3b2;
  font-size: 3vmin;
  line-height: 1.47;
  @include md {
    @include css-lock(16, 26, $mob, $tablet);
    line-height: 1.7; }

  p + p {
    margin-top: 4vmin;
    @include md {
      margin: 0; } }

  ul {
    margin: 4vmin 0;
    padding-left: 4vmin; }

  ul + p,
  ol + p {
    margin-top: 14vmin; }

  li {
    list-style: disc; }

  li + li {
    margin-top: 4vmin; }

  a {
    color: #ffffff; }

  strong, b {
    @include bold;
    color: #fff; }

  img {
    max-width: 100%;
    height: auto;
    margin: 5vmin 0; }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin: 5vmin 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } } }
