@import 'style/helpers';

.about-card {
	padding: 50px 40px 20px;
	height: 100%;
	position: relative;
	z-index: 1;
	min-height: 350px;
	@include r(410) {
		padding: 50px 20px 20px;
		min-height: auto; }
	&:after, &:before {
		content: '';
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0.1;
		background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.45)); }
	&:before {
		background-image: linear-gradient(53deg, #0a050d -5%, #120e17 101%); }
	&__suptitle, &__subtitle {
		font-size: 30px;
		line-height: 1.47;
		margin-bottom: 10px; }
	&__title {
		@include css-lock(40, 80, $tablet, $desktopHD);
		margin-bottom: 20px;
		@include semi-bold;
		@include md {
			@include css-lock(65, 70, $mob, $tablet); }
		@include r(414) {
			@include css-lock(56, 80, 320, $mob); }
		&_sm {
			@include bold;
			font-size: 44px;
			@include r(410) {
				@include css-lock(36, 44, 320, $mob); } } } }


