@import 'style/helpers';

$gradient: linear-gradient(to right, #f419a8 -15%, #7f6fdb 43%, #00f1f9);
$tab_transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

.tabs {
  position: relative;
  width: 100%;
  transition: opacity ease-in 0.4s, visibility ease-in 0.5s, transform 0.6s ease-in;

  &__header {
    position: relative;
    border-bottom: 2px solid #5b5662;
    padding: 0 60px;
    margin-bottom: 60px;
    @include md {
      padding: 0;
      margin-bottom: 30px; } }

  &__list {
    display: flex;
    padding-left: 0;
    list-style: none;
    margin: 0;
    @include md {
      display: block;
      padding: 0 16px;
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: -1px;
      white-space: nowrap; }

    &:before {
      @include md {
        content: '';
        position: absolute;
        right: 0;
        bottom: 11px;

        height: 100%;
        width: 100px;
        box-shadow: inset -140px 0 140px -140px #0f0b15;
        pointer-events: none; } } }

  &__item {
    position: relative;
    padding-bottom: 18px;
    color: #ffffff;
    @include bold;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin-right: 60px;
    flex-shrink: 0;
    font-size: 30px;
    -webkit-tap-highlight-color: transparent;
    @include md {
      display: inline-block;
      font-size: 18px;
      margin-right: 0; }

    &:before {
      @include md {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -2px;
        transform: scaleX(0);
        width: 100%;
        height: 4px;
        background-image: $gradient;
        transform-origin: center;
        transition: transform .2s ease; } }

    &:not(:last-of-type) {
      @include md {
        margin-right: 40px; } }

    &.is-active {
      &:before {
        transform: scaleX(1); } } }

  &__answer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include regular;
    font-size: 30px;
    line-height: 1.6;
    font-weight: normal;
    -webkit-tap-highlight-color: transparent;
    @include md {
      font-size: 18px; } }

  &.enter-active {
    opacity: 0;
    transition: opacity ease-in 0s, visibility ease-in 0.5s, transform 0.6s ease-in; }

  &.enter-done {
    opacity: 1; }

  &.enter-active,
  &.enter-done {
    visibility: visible;
    transform: translateX(0); }

  &.exit-active {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%); }



  &__active-line {
    position: absolute;
    bottom: -2px;
    height: 4px;
    will-change: left, width;
    background-image: $gradient;
    transition: $tab_transition;
    @include md {
      display: none; } } }
