@import 'style/helpers';

.text-block {
  font-size: 52px;
  line-height: 1.48;
  letter-spacing: 0.031em;
  min-height: auto;
  text-align: center;
  @include css-lock(42, 52, $desktop, $desktopHD);
  @include padding;
  padding-top: 0.8rem;
  @include light;

  @include lg {
    @include css-lock(36, 42, $desktop, $desktopHD); }

  @include md {
    padding-bottom: 50px;
    padding-top: 50px;
    @include css-lock(18, 36, $mob, $tablet); }

  &__in {
    margin: auto;
    max-width: 1174px;

    @include lg {
      max-width: 820px; } }

  p {
    &:not(:last-of-type) {
      margin-bottom: 20px; } } }
