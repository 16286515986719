@import 'style/helpers';

.faq-answer {
  $this: &;

  position: relative;
  width: 100%;
  padding: 45px 60px 28px;
  border-bottom: 1px solid rgba(54, 51, 58, 0.8);
  @include md {
    padding: 20px 16px 9px; }

  &__text {
    max-width: 1170px;
    padding-bottom: 10px;
    padding-top: 6px;
    @include regular;
    font-size: 24px;
    color: #a1a3b2;
    line-height: 1.6;
    font-weight: normal;
    @include md {
      font-size: 18px; } }

  &__title {
    position: relative;
    padding-right: 35px;
    padding-bottom: 10px;
    cursor: pointer; }

  &__toggle {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height .4s ease, opacity .3s ease; }

  &__icon {
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 60px;
    @include md {
      top: 16px;
      right: 16px; } }

  &__full-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: 20px;
    line-height: 1.48;
    letter-spacing: 1.52px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #21c4ff;
    cursor: pointer;
    @include md {
      font-size: 14px; }

    svg {
      display: block;
      background: inherit;
      width: 10px;
      margin-left: 14px;
      fill: #21c4ff;
      transition: transform .15s ease;
      transform: translateX(0);
      @include md {
        margin-left: 10px; } }

    &:hover {
      svg {
        transform: translateX(4px); } } }

  &__icon {
    svg {
      display: block;
      flex-shrink: 0;
      width: 24px;
      margin-left: 30px;
      transform: rotate(0);
      transition: transform .2s ease;
      fill: #21c4ff;
      transform-origin: center;
      @include md {
        width: 12px;
        margin-left: 20px; } } }


  &.is-active {
    #{$this}__title {
      @include bold; }

    #{$this}__icon {
      svg {
        transform: rotate(90deg); } } } }
