@import 'style/helpers';

.about-item {
	$this: &;
	display: flex;
	& + & {
		margin-top: 1rem; }
	@include r(768) {
		display: block; }
	&__title {
		width: 47%;
		padding-right: 1rem;
		@include css-lock(42, 46, $desktop, $desktopHD);
		@include semi-bold;
		line-height: 1.39;
		background-clip: text;
		background-image: linear-gradient(to right, #ffeb4f, #00e300 93%);
		text-fill: transparent;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@include md {
			@include css-lock(24, 40, $mob, $tablet); }
		@include r(768) {
			width: auto;
			padding-right: 0;
			margin-bottom: 20px; } }
	&__text {
		@include light;
		width: 53%;
		@include css-lock(26, 30, $desktop, $desktopHD);
		line-height: 1.47;
		letter-spacing: 1.27px;
		p + p {
			margin-top: 0.5rem; }
		@include md {
			@include css-lock(20, 24, $mob, $tablet); }
		@include r(768) {
			width: auto; } }
	&_blue {
		#{$this}__title {
			background-image: linear-gradient(to right, #7f6fdb, #00f1f9 94%); } }
	&_red {
		#{$this}__title {
			background-image: linear-gradient(72deg, #ff2dd6 -9%, #f12d2d 100%); } } }

